<template>
    <v-card v-if="!loading && selectedOrders">
        <v-card-title
                style="background-color: rgba(0, 0, 0, 0.5);color: white;margin-bottom: 8px;justify-content: space-between;">
            Configurar envio {{ courier }}
            <v-img v-if="courier === 'CTT' || courier === 'CTTManual'"
                   src="@/assets/img/logo_ctt.png"
                   style="max-height: 50px; max-width: 110px;text-align:right">
            </v-img>
            <v-img v-if="courier === 'NACEX'"
                   src="@/assets/img/logo_nacex.png"
                   style="max-height: 50px; max-width: 105px;text-align:right">
            </v-img>
            <v-img v-if="courier === 'REDUR'"
                   src="@/assets/img/logo_redur.png"
                   style="max-height: 50px; max-width: 105px;text-align:right">
            </v-img>
            <v-img v-if="courier === 'SEUR2' || courier === 'SEURBikes' || courier === 'SEURManual'"
                   src="@/assets/img/logo_dpd.png"
                   style="max-height: 50px; max-width: 95px;text-align:right">
            </v-img>
            <v-img v-if="courier === 'TRANSNAUTICA'"
                   src="@/assets/img/transnautica.png"
                   style="max-height: 50px; max-width: 125px;text-align:right">
            </v-img>
        </v-card-title>
        <v-card-text class="pa-0">
            <v-form
                    ref="form"
            >
                <!-- TRACKING NUMBER + EDIT BUTTON -->
                <v-row class="ma-0">
                    <v-col class="pt-0" style="display:flex; align-items: center">
                        <strong>Tracking Number: {{ shippingRef.code }}</strong>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn color="info" small @click="buttonChangeAddress()"
                               v-if="client.entity !== 'Envio manual'">
                            {{ changeAddress ? 'Guardar' : 'Editar' }}
                            <i v-if="!changeAddress" class="fas fa-pen pl-1"></i>
                            <i v-if="changeAddress" class="fas fa-check pl-1"></i>
                        </v-btn>
                    </v-col>
                </v-row>

                <!-- INFO CLIENTE -->
                <div class="config-shipping-client-info">
                    <v-row class="ma-0">
                        <v-col class="pb-0">
                            <p>Entidade: <strong>{{ client.entity }}</strong></p>
                            <p v-if="!changeAddress">Nome do cliente: <strong>{{ client.name }}</strong></p>
                            <v-text-field dense v-model="client.name" v-if="changeAddress"
                                          label="Nome do cliente" outlined
                                          :rules="[rules.required]"></v-text-field>
                            <v-text-field dense v-model="client.tel" v-if="changeAddress"
                                          label="Número do telefone" outlined></v-text-field>
                            <p class="mb-0">Morada de Entrega:</p>
                            <v-select
                                    :items="selectedAddress"
                                    outlined
                                    single-line
                                    :rules="[rules.required]"
                                    v-model="inputConfigShip.address"
                                    v-if="!changeAddress"
                                    dense
                            >
                                <template v-slot:item="{ item }">
                                                <span style="font-size:12px;font-weight:bold">
                                                {{ item.Address }} {{ item.Address2 }} {{ item.Locality }} {{ item.Cp }} {{
                                                        item.CpLocality
                                                    }} {{ item.Country }}
                                                </span>
                                </template>
                                <template v-slot:selection="{ item }">
                                                <span style="font-size:12px;font-weight:bold">
                                                {{ item.Address }} {{ item.Address2 }} {{ item.Locality }} {{ item.Cp }} {{
                                                        item.CpLocality
                                                    }} {{ item.Country }}
                                                </span>
                                </template>
                            </v-select>
                            <v-text-field dense v-model="inputConfigShip.address.Address" v-if="changeAddress" outlined
                                          label="Morada 1" counter maxlength="32"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Address2" v-if="changeAddress" outlined
                                          label="Morada 2" counter maxlength="32"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Locality" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Localidade"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Cp" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Código Postal"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.CpLocality" v-if="changeAddress"
                                          outlined
                                          :rules="[rules.required]" label="Código Postal Localidade"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Country" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Pais"></v-text-field>
                        </v-col>
                    </v-row>
                </div>

                <!-- INFO TRANSPORTE -->
                <div class="config-shipping-info-transport">
                    <v-row class="ma-0">
                        <v-col>
                            <!-- only for SEUR/DPD-->
                            <v-row v-if="courier === 'SEUR2' || courier === 'SEURBikes' || courier === 'SEURManual'">
                                <v-col md="6">
                                    <p class="ma-0"><strong>Tipo de Transporte:</strong></p>
                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>
                                        <v-radio value="1" label="Normal"></v-radio>
                                        <v-radio value="17" label="Marítimo"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <!-- only for REDUR -->
                            <v-row v-if="courier === 'REDUR'">
                                <v-col md="6">
                                    <p class="ma-0"><strong>Portes Debidos: </strong></p>
                                    <v-radio-group v-model="inputConfigShip.portesDebidos" row mandatory>
                                        <v-radio value="0" :label="$t('no')"></v-radio>
                                        <v-radio value="1" :label="$t('yes')"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <!-- only for NACEX -->
                            <v-row v-if="courier === 'NACEX'">
                                <v-col md="6">
                                    <p class="ma-0"><strong>Portes Debidos: </strong></p>
                                    <v-radio-group v-model="inputConfigShip.portesDebidos" row mandatory>
                                        <v-radio value="0" :label="$t('no')"></v-radio>
                                        <v-radio value="1" :label="$t('yes')"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col md="6">
                                    <p class="ma-0"><strong>Tipo de Serviço: </strong></p>
                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>
                                        <v-radio value="NOR" :label="'Normal'"></v-radio>
                                        <v-radio value="21" :label="'Sabado'"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <!-- only for CTT -->
                            <v-row v-if="courier === 'CTT' || courier === 'CTTManual'">
                                <v-col md="6">
                                    <p class="ma-0"><strong>Tipo de Serviço: </strong></p>
                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>
                                        <v-radio value="EMSF010.01" :label="'PT Normal'"></v-radio>
                                        <v-radio value="ENCF008.01" :label="'PT Ilhas - Marítmo'"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <p class="ma-0"><strong>Quantidade Caixas: </strong></p>
                            <v-row>
<!--                                <v-col md="10" v-if="courier === 'REDUR'" style="display:flex; align-items:center">-->
<!--                                    <div class="select-box-type" style="min-width: 100%;display: flex">-->
<!--                                        <v-row class="ma-0 mr-1" v-for="box in exampleShippingBoxes" :key="'box_'+box.volume"-->
<!--                                               style="border: 1px solid rgba(0, 0, 0, 0.6);border-radius:0 0 10px 10px;">-->
<!--                                            <v-col cols="12" class="pa-1"-->
<!--                                                   style="display: flex;justify-content: center;min-height: 100px;">-->
<!--                                                <v-img aspect-ratio="16/9" contain-->
<!--                                                       :src="'https://static.bicimax.com/thumbs/'+box.image"/>-->
<!--                                            </v-col>-->
<!--                                            <v-col cols="12" class="pb-1 pt-1 pr-1"-->
<!--                                                   style="display: flex;flex-direction: column;justify-content: center; background-color: rgba(0,0,0,0.06)">-->
<!--                                                <p class="mb-0" style="font-size: 12px">Altura: <strong>{{-->
<!--                                                        box.altura-->
<!--                                                    }}</strong></p>-->
<!--                                                <p class="mb-0" style="font-size: 12px">Largura: <strong>{{-->
<!--                                                        box.largura-->
<!--                                                    }}</strong></p>-->
<!--                                                <p class="mb-0" style="font-size: 12px">Comprimento:-->
<!--                                                    <strong>{{ box.comprimento }}</strong></p>-->
<!--                                                <p class="mb-0" style="font-size: 12px">Volume: <strong>{{-->
<!--                                                        box.volume-->
<!--                                                    }}</strong></p>-->
<!--                                            </v-col>-->
<!--                                            <v-col cols="12"-->
<!--                                                   style="display: flex;justify-content: center;align-items: center">-->
<!--                                                <v-select-->
<!--                                                        :items="numberBoxes"-->
<!--                                                        label="Qnt caixas"-->
<!--                                                        @change="calcTotalSelectedBoxes()"-->
<!--                                                        dense-->
<!--                                                        outlined-->
<!--                                                        v-model="box.quantity"-->
<!--                                                        hide-details-->
<!--                                                ></v-select>-->
<!--                                            </v-col>-->
<!--                                        </v-row>-->

<!--                                    </div>-->
<!--                                </v-col>-->
                                <v-col md="9" style="display:flex; align-items:center">
                                    <v-btn-toggle v-model="inputConfigShip.quantity" :rules="[rules.required]">
                                        <v-btn value="1">1</v-btn>
                                        <v-btn value="2">2</v-btn>
                                        <v-btn value="3">3</v-btn>
                                        <v-btn value="4">4</v-btn>
                                        <v-btn value="5">5</v-btn>
                                        <v-btn value="6">6</v-btn>
                                        <v-btn value="7">7</v-btn>
                                        <v-btn value="8">8</v-btn>
                                        <v-btn value="9">9</v-btn>
                                        <v-btn value="10">10</v-btn>
                                    </v-btn-toggle>
                                </v-col>
                                <v-col :md="'3'" style="display:flex; align-items:center">
                                    Total:
<!--                                    <v-text-field v-if="courier === 'REDUR'" disabled dense :rules="[rules.required]"-->
<!--                                                  v-model="totalRedurBoxes"-->
<!--                                                  outlined single-line-->
<!--                                                  style="display:flex; align-items:center;margin-bottom: -22px;"></v-text-field>-->
                                    <v-text-field dense :rules="[rules.required]"
                                                  v-model="inputConfigShip.quantity"
                                                  outlined single-line
                                                  style="display:flex; align-items:center;margin-bottom: -22px;"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="4">
                                    <p class="mb-1"><strong>Peso: </strong></p>
                                    <v-text-field dense v-model="inputConfigShip.weight" placeholder="Kg" label="Kg"
                                                  outlined
                                                  style="width: 150px" :rules="[rules.required]"></v-text-field>
                                </v-col>
                                <v-col md="4">
                                    <p class="mb-2">
                                        <strong>
                                            Contra-Reembolso:
                                        </strong>
                                    </p>
                                    <v-btn tile small :color="inputConfigShip.payment === 'yes' ? 'success' : ''"
                                           @click="updatePayment('yes')">
                                        Sim
                                    </v-btn>
                                    <v-btn tile small :color="inputConfigShip.payment === 'no' ? 'error' : ''"
                                           @click="updatePayment('no')">
                                        Não
                                    </v-btn>
                                </v-col>
                                <v-col md="4" v-if="inputConfigShip.payment === 'yes'">
                                    <p class="mb-1">
                                        <strong>
                                            Preço a cobrar:
                                        </strong>
                                    </p>
                                    <v-text-field dense v-model="inputConfigShip.price" label="€" placeholder="€"
                                                  outlined
                                                  style="width: 150px"
                                    ></v-text-field>
                                </v-col>
                            </v-row>


                            <v-divider class="mb-4"/>
                            <p class="mb-0">
                                <strong>
                                    Observações:
                                </strong>
                            </p>
                            <v-text-field dense v-model="inputConfigShip.observation"
                                          placeholder="Observação sobre o envio..." outlined
                                          hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </div>


            </v-form>
        </v-card-text>
        <v-card-actions style="background-color: rgba(0, 0, 0, 0.1);">
            <v-btn small width="180" @click="closeModal()" color="error">
                Cancelar
                <svg class="pl-1" xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 384 512">
                    <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                    <path fill="#ffffff"
                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                </svg>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn small width="180" @click="decideShippingByCourier()" color="success"
                   :disabled="inputConfigShip.payment === ''">
                Criar Etiqueta<i class="fas fa-tag pl-1"></i>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import i18n from "@/plugins/i18n";

export default {
    props: {
        courier: {
            require: true
        },
        client: {
            require: true
        },
        emptyInputConfigShip: {
            require: true
        },
        addresses: {},
        selectedOrders: {}
    },
    data: function () {
        return {
            loading: true,
            inputConfigShip: {},
            rules: {
                required: value => !!value || value === '0' || i18n.t('required'),
            },
            changeAddress: false,
            selectedAddress: {},
            shippingRef: {},
            seurCodesAccounts: {
                'auto': {
                    'pt': '07287606',
                    'es': '07287604'
                },
                'auto_sea': {
                    'pt': '07287607',
                    'es': '07287605'
                },
                'bikes': '07287612'
            },
            seurCodes: {
                'normal': 'auto',
                'bikes': '07287612',
                'sea': 'auto_sea',
            },
            numberBoxes: [],
            shippingBoxes: [],
            totalRedurBoxes: 0,
            exampleShippingBoxes:[
                {volume: 100,comprimento:50,largura:20,altura:10,image:'51cBnl28PRL.jpg',},
                {volume: 200,comprimento:100,largura:40,altura:25,image:'51GkPNYujsL.jpg',}
            ]
        }
    },
    mounted() {
        this.initializeValues()
        this.fillNumbers()
    },
    methods: {
        //Fill number of select boxes to 50
        fillNumbers() {
            for (let i = 0; i <= 50; i++) {
                this.numberBoxes.push(i);
            }
        },
        //Update values - Edit address
        buttonChangeAddress() {
            this.changeAddress = !this.changeAddress
        },
        //Update values - Contra-Reembolso
        updatePayment(option) {
            this.inputConfigShip.payment = option
        },
        //Close the modal
        closeModal() {
            //clear inputValues
            this.inputConfigShip = JSON.parse(JSON.stringify(this.emptyInputConfigShip))
            this.$emit('close-modal', false);
        },
        //Call getOrders from parent
        getOrdersWaitingShipping() {
            this.$emit('get-orders', false);
        },

        //Initialize values by courier
        initializeValues() {
            //Mount values from props
            this.inputConfigShip = JSON.parse(JSON.stringify(this.emptyInputConfigShip))
            this.selectedAddress = JSON.parse(JSON.stringify(this.addresses))

            //Check courier selected
            if (this.courier == 'CTT') {
                this.configShippingCTT()
            }
            if (this.courier == 'CTTManual') {
                this.CTTManual()
            }
            if (this.courier == 'SEUR2') {
                this.configShippingSEUR2()
            }
            if (this.courier == 'SEURBikes') {
                this.configShippingSEURBikes()
            }
            if (this.courier == 'SEURManual') {
                this.SEURManual()
            }
            if (this.courier == 'REDUR') {
                // this.getShippingBoxes()
                this.configShippingREDUR()
            }
            if (this.courier == 'NACEX') {
                this.configShippingNACEX()
            }
            if (this.courier == 'TRANSNAUTICA') {
                this.configShippingTRANSNAUTICA()
            }
        },

        //Get data from customer to ship
        getDataCustomerToShip() {
            this.loading = true;
            let loader = this.$loading.show();
            let order = {
                clientCode: this.client.entity,
                idDoc: this.selectedOrders[0].id
            }

            Order.dispatch('getCustomerDetail', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.selectedAddress = responseData.addresses
                            if (this.selectedAddress.length === 1) {
                                this.inputConfigShip.address = this.selectedAddress[0]
                            }else {
                                //this.inputConfigShip.address = this.selectedOrders[0]
                                //console.log(this.selectedAddress)
                                this.inputConfigShip.address = this.selectedAddress.find(address => address.ID === this.selectedOrders[0].shippingAddress);
                            }
                            if (this.client.entity === 'VD' || this.client.entity === 'VDSHOP' || this.client.entity === 'VDES' || this.client.entity === 'VDSHOPES') {
                                this.client.name = responseData.client.Nome
                            }
                            loader.hide()
                        }
                    })
                    .catch(response => {
                        loader.hide()

                    })
            this.loading = false;
        },
        //Get shipping boxes for REDUR
        // getShippingBoxes() {
        //     this.loading = true;
        //     let loader = this.$loading.show();
        //
        //     Order.dispatch('getShippingBoxes')
        //             .then(response => {
        //                 let responseData = response.data
        //                 if (responseData.status === 'OK') {
        //                     this.shippingBoxes = responseData.shippingBoxes
        //
        //                     //Initialize box with 0 of each one
        //                     for (const box of this.shippingBoxes) {
        //                         box.quantity = 0
        //                     }
        //
        //                     loader.hide()
        //                 }
        //             })
        //             .catch(response => {
        //                 loader.hide()
        //
        //             })
        //     this.loading = false;
        // },
        //Calc total boxes selected
        calcTotalSelectedBoxes() {
            this.totalRedurBoxes = 0
            for (let box of this.shippingBoxes) {
                this.totalRedurBoxes += box.quantity
            }
        },

        //FUNCTIONS TO CONFIG CREATE TAG SHIPPING
        configShippingCTT() {
            this.loading = true;
            let order = {
                courier: 'CTT',
                clientCode: this.client.entity,
                clientName: this.client.name,
                salesman: this.selectedOrders[0].vendedor,
                orders: this.selectedOrders
            };

            //Defaults
            this.inputConfigShip.type = 'EMSF010.01';

            //Get Tracking
            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.shippingRef = {};
                            this.inputConfigShip = {
                                price: '',
                                address: '',
                                payment: '',
                                quantity: 0,
                                weight: '',
                                observation: '',
                                account: '',
                                type: 'EMSF010.01'
                            };

                            this.getDataCustomerToShip();
                            this.shippingRef = responseData.ref;
                            this.inputConfigShip.account = '';

                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar envio!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })

            this.loading = false;
        },
        configShippingSEUR2() {
            this.createOrUpdateShippingPackageSEUR(this.seurCodes.normal);
        },
        configShippingSEURBikes() {
            this.createOrUpdateShippingPackageSEUR(this.seurCodes.bikes);
        },
        createOrUpdateShippingPackageSEUR(account) {
            this.loading = true;
            let order = {
                courier: 'SEUR2',
                clientCode: this.client.entity,
                clientName: this.client.name,
                salesman: this.selectedOrders[0].vendedor
            };
            let loader = this.$loading.show();

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.shippingRef = {};
                            this.inputConfigShip = {
                                price: '',
                                address: '',
                                payment: '',
                                quantity: 0,
                                weight: '',
                                observation: '',
                                account: '897'
                            };

                            this.getDataCustomerToShip();
                            this.shippingRef = responseData.ref;
                            this.inputConfigShip.account = account;
                        }
                        loader.hide();
                    })
                    .catch(response => {
                        loader.hide();
                    })
            this.loading = false;
        },
        configShippingREDUR() {
            this.loading = true;
            let order = {
                courier: 'REDUR',
                clientCode: this.client.entity,
                clientName: this.client.name,
                salesman: this.selectedOrders[0].vendedor,
                orders: this.selectedOrders
            };

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.shippingRef = {};
                            this.inputConfigShip = {
                                price: '',
                                address: '',
                                payment: '',
                                quantity: 0,
                                weight: '',
                                observation: '',
                                account: ''
                            };

                            this.getDataCustomerToShip();
                            this.shippingRef = responseData.ref;
                            this.inputConfigShip.account = '';

                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar envio!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            this.loading = false;
        },
        configShippingNACEX() {
            this.loading = true;
            let order = {
                courier: 'NACEX',
                clientCode: this.client.entity,
                clientName: this.client.name,
                salesman: this.selectedOrders[0].vendedor,
                orders: this.selectedOrders
            };

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.shippingRef = {};
                            this.inputConfigShip = {
                                price: '',
                                address: '',
                                payment: '',
                                quantity: 0,
                                weight: '',
                                observation: '',
                                account: '',
                                type: 'NOR'
                            };

                            this.getDataCustomerToShip();
                            this.shippingRef = responseData.ref;
                            this.inputConfigShip.account = '';
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar envio!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            this.loading = false
        },
        configShippingTRANSNAUTICA(){
            this.loading = true;
            let order = {
                courier: 'TRANSNAUTICA',
                clientCode: this.client.entity,
                clientName: this.client.name,
                salesman: this.selectedOrders[0].vendedor,
                orders: this.selectedOrders
            }

            //GetTrackingNumber
            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.shippingRef = {};
                            this.inputConfigShip = {
                                price: '',
                                address: '',
                                payment: '',
                                quantity: 0,
                                weight: '',
                                observation: '',
                                account: '',
                                type: 'NOR'
                            };

                            this.getDataCustomerToShip();
                            this.shippingRef = responseData.ref;
                            this.inputConfigShip.account = '';
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar envio!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })

            this.loading = false;
        },
        CTTManual() {
            this.loading = true;
            let order = {
                courier: 'CTT',
                clientCode: '',
                clientName: '',
                salesman: ''
            }
            this.client.entity = 'Envio manual'
            this.client.name = ''
            this.client.tel = ''

            this.inputConfigShip.address = {
                Address: '',
                Address2: '',
                Locality: '',
                Cp: '',
                CpLocality: '',
                Country: ''
            }

            this.inputConfigShip.account = 'NULL';

            //Clear selected orders on parent
            // this.$emit('clear-selected-orders');

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dialogConfigCTT = true
                            this.changeAddress = true
                            this.shippingRef = responseData.ref
                        }
                    })
                    .catch(response => {

                    })

            this.loading = false;
        },
        SEURManual() {
            this.loading = true;
            let order = {
                courier: 'SEUR2',
                clientCode: '',
                clientName: '',
                salesman: ''
            }
            this.client.entity = 'Envio manual'
            this.client.name = ''
            this.client.tel = ''

            this.inputConfigShip.address = {
                Address: '',
                Address2: '',
                Locality: '',
                Cp: '',
                CpLocality: '',
                Country: ''
            }

            this.inputConfigShip.account = this.seurCodes.normal;

            //Clear selected orders on parent
            // this.$emit('clear-selected-orders');

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.changeAddress = true
                            this.shippingRef = responseData.ref
                        }
                    })
                    .catch(response => {

                    })

            this.loading = false;
        },

        //Functions to create shipping and tag
        decideShippingByCourier() {
            switch (this.courier) {
                case 'CTT':
                case 'CTTManual':
                    this.createShippingCTT();
                    break;
                case 'REDUR':
                    this.createShippingRedur();
                    break;
                case 'NACEX':
                    this.createShippingNacex();
                    break;
                case 'SEUR2':
                case 'SEURBikes':
                case 'SEURManual':
                    this.createShippingSEUR();
                    break;
                case 'TRANSNAUTICA':
                    this.createShippingTransnautica();
                    break;
                default:
                    console.log('No courier found!')
            }
        },
        createShippingCTT() {
            if (this.$refs.form.validate()) {
                let shipping = {
                    client: this.client,
                    shipping: this.inputConfigShip,
                    trackingCode: this.shippingRef,
                    selectedOrders: this.selectedOrders,
                    courier: this.courier
                }

                let loader = this.$loading.show();
                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                this.closeModal();
                                this.filters = {
                                    entity: '',
                                    name: '',
                                    typeDoc: '',
                                    numDoc: '',
                                    week: 1
                                }

                                this.pagination.page = 1

                                //Emit getOrdersWaitingShipping to parent
                                this.getOrdersWaitingShipping()
                                this.$emit('clear-selected-orders');

                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }

                                loader.hide();
                                Alert.dispatch('show', alert)
                            } else if (responseData.status === 'WARN') {
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                loader.hide()
                                Alert.dispatch('show', alert)
                            } else {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio!',
                                    type: 'error'
                                }

                                loader.hide()
                                Alert.dispatch('show', alert)
                            }
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            loader.hide()
                        })
            }
        },
        createShippingSEUR() {
            if (this.$refs.form.validate()) {
                let shipping = {
                    client: this.client,
                    shipping: this.inputConfigShip,
                    trackingCode: this.shippingRef,
                    selectedOrders: this.selectedOrders,
                    courier: 'SEUR2'
                }

                let loader = this.$loading.show();
                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                this.closeModal()
                                this.filters = {
                                    entity: '',
                                    name: '',
                                    typeDoc: '',
                                    numDoc: '',
                                    week: 1
                                }

                                this.pagination.page = 1

                                //Emit getOrdersWaitingShipping to parent
                                this.getOrdersWaitingShipping()
                                this.$emit('clear-selected-orders');

                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }
                                loader.hide();
                                Alert.dispatch('show', alert)
                            } else if (responseData.status === 'WARN') {
                                loader.hide();
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            } else {
                                loader.hide();
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio! ' + responseData.data.message,
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            }
                        })
                        .catch(response => {
                            loader.hide();
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
            }
        },
        createShippingRedur() {
            if (this.$refs.form.validate()) {
                let shipping = {
                    client: this.client,
                    shipping: this.inputConfigShip,
                    trackingCode: this.shippingRef,
                    selectedOrders: this.selectedOrders,
                    courier: this.courier
                }
                let loader = this.$loading.show();
                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                this.closeModal();
                                this.filters = {
                                    entity: '',
                                    name: '',
                                    typeDoc: '',
                                    numDoc: '',
                                    week: 1
                                }

                                this.pagination.page = 1

                                //Emit getOrdersWaitingShipping to parent
                                this.getOrdersWaitingShipping()
                                this.$emit('clear-selected-orders');

                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }

                                loader.hide()
                                Alert.dispatch('show', alert)
                            } else if (responseData.status === 'WARN') {
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                loader.hide()
                                Alert.dispatch('show', alert)
                            } else {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio!',
                                    type: 'error'
                                }

                                loader.hide()
                                Alert.dispatch('show', alert)
                            }
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            loader.hide()
                        })
            }
        },
        createShippingNacex() {
            if (this.$refs.form.validate()) {
                let shipping = {
                    client: this.client,
                    shipping: this.inputConfigShip,
                    trackingCode: this.shippingRef,
                    selectedOrders: this.selectedOrders,
                    courier: this.courier
                }
                let loader = this.$loading.show();
                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                this.closeModal();
                                this.filters = {
                                    entity: '',
                                    name: '',
                                    typeDoc: '',
                                    numDoc: '',
                                    week: 1
                                }

                                this.pagination.page = 1

                                //Emit getOrdersWaitingShipping to parent
                                this.getOrdersWaitingShipping()
                                this.$emit('clear-selected-orders');

                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }

                                loader.hide()
                                Alert.dispatch('show', alert)
                            } else if (responseData.status === 'WARN') {
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                loader.hide()
                                Alert.dispatch('show', alert)
                            } else {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio!',
                                    type: 'error'
                                }

                                loader.hide()
                                Alert.dispatch('show', alert)
                            }
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            loader.hide()
                        })
            }
        },
        createShippingTransnautica(){
            if (this.$refs.form.validate()) {
                let shipping = {
                    client: this.client,
                    shipping: this.inputConfigShip,
                    trackingCode: this.shippingRef,
                    selectedOrders: this.selectedOrders,
                    courier: this.courier
                }
                // console.log(shipping)

                let loader = this.$loading.show();
                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                this.closeModal();
                                this.filters = {
                                    entity: '',
                                    name: '',
                                    typeDoc: '',
                                    numDoc: '',
                                    week: 1
                                }

                                this.pagination.page = 1

                                //Emit getOrdersWaitingShipping to parent
                                this.getOrdersWaitingShipping()
                                this.$emit('clear-selected-orders');

                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }

                                loader.hide()
                                Alert.dispatch('show', alert)
                            } else if (responseData.status === 'WARN') {
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                loader.hide()
                                Alert.dispatch('show', alert)
                            } else {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio!',
                                    type: 'error'
                                }

                                loader.hide()
                                Alert.dispatch('show', alert)
                            }
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            loader.hide()
                        })
            }
        }
    },
    computed: {}
}

</script>

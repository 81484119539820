<style>
.budget-products-table {
    .discount1, .discount2, .discount3 {

        /* REMOVE ARROWS FROM INPUT NUMBER */
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }

        input {
            text-align: center;
            font-weight: bold;
            font-size: 14px;
        }

        .v-input__slot {
            min-height: 30px !important;
            border-radius: 0;
        }
    }

    .discount1 {
        input {
            color: #66b050 !important;
        }
    }

    .discount2 {
        input {
            color: #4da7ed;
        }
    }

    .discount3 {
        input {
            color: #e56dcd;
        }
    }
}
</style>
<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title :title="$t('budgetCalculator')"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small @click="clearBudget" color="error">Limpar Orçamento</v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <template v-if="loading">
            <v-row class="mt-12">
                <v-col class="text-center">
                    <i class="fas fa-cog fa-spin fa-3x"></i>
                </v-col>
            </v-row>
        </template>

        <template v-if="!loading">
            <!-- ADD PRODUCTS TO BUDGET - ACTIONS -->
            <v-row>
                <v-col cols="4">
                    <v-autocomplete
                            v-model="productInsert.product"
                            :items="productsList"
                            item-text="Artigo"
                            item-value="Artigo"
                            :label="$t('product')"
                            append-icon="search"
                            outlined
                            dense
                            hide-details
                            clearable
                            @keypress.enter="insertProduct"
                            @focus="$event.target.select()"
                            ref="fastInsertProduct"
                    >
                        <template slot="item" slot-scope="data">
                            {{ data.item.Artigo }} - {{ data.item.Descricao }}
                        </template>
                    </v-autocomplete>
                </v-col>
                <!--                <v-col cols="1">-->
                <!--                    <v-text-field-->
                <!--                            v-model="productInsert.quantity"-->
                <!--                            outlined-->
                <!--                            dense-->
                <!--                            hide-details-->
                <!--                            :label="$t('quantity')"-->
                <!--                            ref="fastInsertQuantity"-->
                <!--                            @focus="$event.target.select()"-->
                <!--                            @keypress.enter="insertProduct"-->
                <!--                    ></v-text-field>-->
                <!--                </v-col>-->
                <v-col>
                    <v-btn tile color="success" @click="insertProduct">Adicionar</v-btn>
                </v-col>
                <v-col>
                    <v-btn tile style="float: right" color="info" :loading="excelLoading" @click="downloadExcel">Exportar Excel</v-btn>
                </v-col>
            </v-row>

            <!-- BUDGETS PRODUCTS TABLE -->
            <v-row>
                <v-col>
                    <!-- PRODUCTS ON BUDGET TABLE -->
                    <v-simple-table dense class="budget-products-table staff_table">
                        <thead>
                        <tr class="titles_row">
                            <th>{{ $t('product') }}</th>
                            <th>{{ $t('description') }}</th>
                            <th width="1">CodBarras</th>
                            <th class="text-center">{{ $t('price') }}Custo</th>
                            <th class="text-center">PVP1</th>
                            <th class="text-center">{{ $t('discount') }}1</th>
                            <th class="text-center">{{ $t('discount') }}2</th>
                            <th class="text-center">{{ $t('discount') }}3</th>
                            <th class="text-center">{{ $t('price') }} Final</th>
                            <th class="text-center">Margem (€)</th>
                            <th class="text-center">Margem (%)</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="productsOnBudget.length === 0">
                            <td colspan="11" class="text-center">
                                Sem produtos selecionados!
                            </td>
                        </tr>
                        <tr v-for="product in productsOnBudget">
                            <td>
                                {{ product.Artigo }}
                            </td>
                            <td>
                                {{ product.Descricao }}
                            </td>
                            <td  width="100px">
                                {{ product.CodBarras }}
                            </td>
                            <td class="text-center">
                                {{ $money(product.PCMedio) }}
                            </td>
                            <td class="text-center">
                                {{ $money(product.PVP1) }}
                            </td>
                            <td width="50px">
                                <v-text-field
                                        class="discount1"
                                        label="%"
                                        hide-details dense
                                        type="number"
                                        v-model="product.Desconto1"
                                        @focus="$event.target.select()"
                                        @focusout="product.Desconto1 == '' ? product.Desconto1 = 0 : ''"
                                        outlined
                                        background-color="rgba(0,0,0,0.04)"
                                ></v-text-field>
                            </td>
                            <td width="50px">
                                <v-text-field
                                        class="discount2"
                                        label="%"
                                        hide-details dense
                                        type="number"
                                        v-model="product.Desconto2"
                                        @focus="$event.target.select()"
                                        @focusout="product.Desconto2 == '' ? product.Desconto2 = 0 : ''"
                                        outlined
                                        background-color="rgba(0,0,0,0.04)"
                                ></v-text-field>
                            </td>
                            <td width="50px">
                                <v-text-field
                                        class="discount3"
                                        label="%"
                                        hide-details dense
                                        type="number"
                                        v-model="product.Desconto3"
                                        @focus="$event.target.select()"
                                        @focusout="product.Desconto3 == '' ? product.Desconto3 = 0 : ''"
                                        outlined
                                        background-color="rgba(0,0,0,0.04)"
                                ></v-text-field>
                            </td>
                            <td class="text-center">
                                <!-- PrecoFinal com descontos -->
                                {{ $money(calcFinalPrice(product)) }}
                            </td>
                            <td class="text-center" style="display: flex;margin-top:1px">
                                <!-- Margem de lucro com base no PFinal -->
                                <span style="padding: 9px;min-width: 100%"
                                      :style="[calcPriceMargin(product) > 0 ? {backgroundColor: 'rgba(0,129,0,0.2)'} : {backgroundColor: 'rgba(255,0,0,0.2)'}]">
                                    {{ $money(calcPriceMargin(product)) }}
                                </span>
                            </td>
                            <td class="text-center">
                                <!-- % da Margem de lucro com base no PFinal -->
                                {{ Math.round(calcPriceMarginPercentage(product)) }}%
                            </td>
                            <td width="30px">
                                <v-btn x-small icon @click="removeProductFromBudget(product.Artigo)">
                                    <v-icon color="error">close</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>

        </template>

    </v-container>
</template>

<script>
import i18n from '../../plugins/i18n'

export default {
    data: function () {
        return {
            loading: true,
            excelLoading: false,
            productsList: [],
            productsOnBudget: [],
            productInsert: {
                product: '',
                description: '',
                quantity: 1
            },
        }
    },
    created() {
        document.title = 'Comercial ' + i18n.t('budgets');
        this.getAllProducts();
    },
    methods: {
        clearBudget() {
            this.productsOnBudget = [];
        },
        getAllProducts() {
            ApiWS.dispatch('makeGet', {url: '/api/staff/products/all-prices'})
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.productsList = responseData.products;
                            this.loading = false;
                        }
                    })
        },
        insertProduct() {
            let productToAdd = this.productsList.filter(item => item.Artigo === this.productInsert.product);
            // console.log(productToAdd)
            // console.log(productToAdd.length)
            if (productToAdd.length === 1) {
                //validar se orçamento já tem produto
                let productInBudget = this.productsOnBudget.filter(item => item.Artigo === productToAdd.Artigo)
                if (productInBudget.length === 0) {
                    //Adicionar produto
                    this.productsOnBudget.push(productToAdd[0]);

                    //Clear product to insert
                    this.productInsert = {
                        product: '',
                        description: '',
                        quantity: 1
                    }
                } else {
                    //Aumentar quantidade ?
                }
            }
        },
        calcFinalPrice(product) {
            if (product.Desconto1 != 100 && product.Desconto2 != 100 && product.Desconto3 != 100)
                product.finalPrice = (((product.PVP1 * ((100 - product.Desconto1) / 100))) * ((100 - product.Desconto2) / 100)) * ((100 - product.Desconto3) / 100)
            else
                product.finalPrice = product.PVP1

            return product.finalPrice
        },
        calcPriceMargin(product) {
            if (product.finalPrice) {
                product.margin = product.finalPrice - product.PCMedio
                return product.margin;
            } else {
                return 'NA'
            }
        },
        calcPriceMarginPercentage(product) {
            if (product.finalPrice) {
                product.marginPercentage = ((product.finalPrice - product.PCMedio) / product.finalPrice) * 100
                return product.marginPercentage
            } else {
                return 'NA'
            }
        },
        removeProductFromBudget(productArtigo) {
            this.productsOnBudget.map((item, index) => {
                if (item.Artigo === productArtigo) {
                    this.productsOnBudget.splice(index, 1);
                }
            })
        },
        downloadExcel() {
            this.excelLoading = true;

            var cloneProductsOnBudget = JSON.parse(JSON.stringify(this.productsOnBudget));
            //Prepare values
            for (const product of cloneProductsOnBudget) {
                product.PVP1 = this.$money(product.PVP1);
                product.PCMedio = this.$money(product.PCMedio);
                product.finalPrice = this.$money(product.finalPrice);
                product.margin = this.$money(product.margin);
                product.marginPercentage = Math.round(product.marginPercentage);
            }
            let request = {
                url: '/api/staff/products/budget/excel',
                data: {
                    productsOnBudget: cloneProductsOnBudget
                }
            }
            console.log(request);

            //Make request
            ApiWS.dispatch('makePostDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = 'budget_' + new Date().valueOf() + '.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        // loader.hide();
                        this.excelLoading = false;
                    })
                    .catch(response => {
                        // loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.excelLoading = false;
                    })


        }
    },
    computed: {}
}

</script>
<style>
.transporter-option {
    display: flex;
    justify-content: center;
    align-items: center;

    .v-card {
        max-width: 100px;
    }
}

#transporter-options-row {
    background-color: #f5f5f5;
    justify-content: space-evenly;

    .transporters-title {
        background-color: rgba(0, 151, 167, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
    }

    .transporter-option {
        .v-card {
            max-width: 100px;
        }
    }
}

#print_manifest_nacex {
    transition: all 0.2s ease-in-out;
    font-size: 10px;
    .transporter{
        color: #ff5a00;
        font-weight: bold;
        font-size: 14px;
    }

    &:hover {
        background-color: #ff5a00 !important;
        border-color: #ff5a00;

        .transporter{
            color: black;
        }
    }
}

#print_manifest_ctt {
    transition: all 0.2s ease-in-out;
    font-size: 10px;
    .transporter{
        color: #ce000c;
        font-weight: bold;
        font-size: 14px;
    }

    &:hover {
        background-color: #ce000c !important;
        border-color: #ce000c;
        .transporter{
            color: black;
        }
    }
}

#print_manifest_redur {
    transition: all 0.2s ease-in-out;
    font-size: 10px;
    .transporter{
        color: #90d264;
        font-weight: bold;
        font-size: 14px;
    }

    &:hover {
        background-color: #90d264 !important;
        border-color: #90d264;
        .transporter{
            color: black;
        }
    }
}

#print_manifest_transnautica {
    transition: all 0.2s ease-in-out;
    font-size: 10px;
    .transporter{
        color: #00a0df;
        font-weight: bold;
        font-size: 14px;
    }

    &:hover {
        background-color: #00a0df !important;
        border-color: #00a0df;
        .transporter{
            color: black;
        }
    }
}

#hide-doc-btn {
    transition: all 0.2s ease-in-out;

    .show-hover {
        display: none;
    }

    &:hover {
        background-color: red;

        .hide-hover {
            display: none;
        }

        .show-hover {
            display: block;
            color: white;
        }
    }
}

.config-shipping-client-info {
    background-color: rgba(0, 0, 0, 0.06);
}
</style>
<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="12" md="6">
                <page-title title="Configurar Envios"></page-title>
            </v-col>
        </v-row>

        <!-- SHIPPINGS TO DO + TRANSPORTERS -->
        <v-row>
            <v-col cols="10" class="pa-2">
                <v-card>
                    <v-toolbar class="mb-0" flat dense dark color="cyan darken-2">
                        <v-toolbar-title><i class="fas fa-file-invoice pr-1"></i>Selecionar Faturas</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn small outlined @click="toggleDialogRecoverDoc()">Recuperar Documento</v-btn>
                        <v-btn icon @click="getOrdersWaitingShipping()" :loading="loading">
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text class="pa-0" style="height: 80%;">
                        <v-row id="transporter-options-row" class="ma-0">
                            <v-col md="12" class="pa-0 transporters-title"><span style="color: black;">ESCOLHER TRANSPORTADORA</span>
                            </v-col>
                            <v-col md="1" class="transporter-option pa-1">
                                <v-card hover :disabled="!selectedOrders.length">
                                    <!-- :src="urlAPI+'/assets/img/dpd_auto.jpg'" -->
                                    <v-img
                                            src="@/assets/img/dpd_auto.jpg"
                                            contain
                                            class="ma-1"
                                            @click="configShipping('SEUR2')"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="1" class="transporter-option pa-2">
                                <v-card hover :disabled="!selectedOrders.length">
                                    <!-- :src="urlAPI+'/assets/img/dpd_bicicletas.jpg'" -->
                                    <v-img
                                            src="@/assets/img/dpd_bicicletas.jpg"
                                            contain
                                            class="ma-1"
                                            @click="configShipping('SEURBikes')"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="1" class="transporter-option pa-2">
                                <v-card hover>
                                    <!-- :src="urlAPI+'/assets/img/dpd_manual.jpg'" -->
                                    <v-img
                                            src="@/assets/img/dpd_manual.jpg"
                                            contain
                                            class="ma-1"
                                            @click="configShipping('SEURManual')"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="1" class="transporter-option pa-2" v-if="false">
                                <v-card hover class="mx-1">
                                    <v-img
                                            :src="urlAPI+'/assets/img/dhl.jpg'"
                                            contain
                                            class="ma-1"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="1" class="transporter-option pa-2">
                                <v-card hover>
                                    <v-img
                                            :src="urlAPI+'/assets/img/ctt-manual.png'"
                                            contain
                                            class="ma-1"
                                            @click="configShipping('CTTManual')"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="1" class="transporter-option pa-2">
                                <v-card hover :disabled="!selectedOrders.length">
                                    <v-img
                                            :src="urlAPI+'/assets/img/ctt.jpg'"
                                            contain
                                            class="ma-1"
                                            @click="configShipping('CTT')"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="1" class="transporter-option pa-2">
                                <v-card hover :disabled="!selectedOrders.length">
                                    <v-img
                                            :src="urlAPI+'/assets/img/redur.jpg'"
                                            contain
                                            class="ma-1"
                                            @click="configShipping('REDUR')"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="1" class="transporter-option pa-2">
                                <v-card hover :disabled="!selectedOrders.length">
                                    <v-img
                                            :src="urlAPI+'/assets/img/nacex.png'"
                                            contain
                                            @click="configShipping('NACEX')"
                                            class="ma-1"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="1" class="transporter-option pa-2">
                                <v-card hover :disabled="!selectedOrders.length" style="min-height: 100%; align-content: center;">
                                    <!--  :src="urlAPI+'/assets/img/transnautica.png'"  -->
                                    <v-img
                                            :src="require('../../assets/img/transnautica_2.png')"
                                            height="90"
                                            contain
                                            @click="configShipping('TRANSNAUTICA')"
                                            class="ma-1"
                                    >
                                    </v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                        <!-- TABLE PARA SELECIONAR FATURAS -->
                        <v-simple-table class="staff_table" style="min-height: 80%">
                            <thead>
                            <tr class="titles_row">
                                <th style="width: 90px">Entidade</th>
                                <th>Nome do Cliente</th>
                                <th style="width: 60px">TipoDoc</th>
                                <th style="width: 100px" class="text-center">NumDoc</th>
                                <th style="width: 70px" class="text-center">PackList</th>
                                <th style="width: 120px" class="text-right">Valor Total</th>
                                <th style="width: 140px" class="text-right">Data Documento</th>
                                <th style="width: 1px"></th>
                            </tr>
                            <tr class="filters_row">
                                <td>
                                    <v-text-field
                                            v-model="filters.entity"
                                            outlined
                                            hide-details
                                            single-line
                                            @keyup.enter="getOrdersWaitingShipping"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filters.name"
                                            outlined
                                            hide-details
                                            single-line
                                            @keyup.enter="getOrdersWaitingShipping"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filters.typeDoc"
                                            outlined
                                            hide-details
                                            single-line
                                            @keyup.enter="getOrdersWaitingShipping"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filters.numDoc"
                                            outlined
                                            hide-details
                                            single-line
                                            @keyup.enter="getOrdersWaitingShipping"
                                    ></v-text-field>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody style="border-bottom: 1px solid ">
                            <tr v-if="loading">
                                <td colspan="7">
                                    <v-progress-linear indeterminate></v-progress-linear>
                                </td>
                            </tr>
                            <tr v-for="order in ordersToSend" @click="selectOrder(order)"
                                :class="{'grey lighten-2': selectedOrdersIds.indexOf(order.id) >= 0}">
                                <td class="pl-2">{{ order.client_code }}</td>
                                <td>{{ order.client_name }}</td>
                                <td class="text-center">{{ order.doc_type }}</td>
                                <td class="text-center">{{ order.doc_number }}</td>
                                <td class="text-center">
                                    <v-btn tile small color="warning" :to="'/orders/packingList/'+order.doc_type+'/'+order.doc_number">
                                        caixas
                                    </v-btn>
                                </td>
                                <td class="text-right" style="white-space: nowrap">{{
                                        formatPrice(order.order_total)
                                    }} €
                                </td>
                                <td class="text-right">{{ order.order_date }}</td>
                                <td class="text-center">
                                    <v-btn id="hide-doc-btn" color="" outlined small
                                           @click="toggleDialogHideDoc(order.id)">
                                        <i class="far fa-eye hide-hover"></i>
                                        <i class="far fa-eye-slash show-hover"></i>
                                    </v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions class="mt-4" style="border-top: 1px solid #dedede;">
                        <!-- PAGINAÇÃO + INTERVALO -->
                        <v-row class="ma-0">
                            <v-col :md="2" class="pt-0 pb-0" style="align-content: center;">
                                <v-select
                                        v-model="pagination.per_page"
                                        :items="itemPerPage"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        label="Faturas por página"
                                        class="staff_select"
                                        @change="getOrdersWaitingShipping"
                                ></v-select>
                            </v-col>
                            <v-col class="pt-0 pb-0">
                                <v-pagination
                                        v-model="pagination.page"
                                        :length="getTotalPages"
                                        :total-visible="7"
                                        color="#0097a7"
                                        @input="getOrdersWaitingShipping"
                                ></v-pagination>
                            </v-col>
                            <v-col :md="2" class="text-right pt-0 pb-0" style="align-content: center;">
                                <v-select
                                        v-model="filters.week"
                                        :items="filterWeek"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="getOrdersWaitingShipping"
                                        label="Intervalo de Tempo"
                                        class="staff_select"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- STATISTICS - PRINT DEPOR + EAN AND REF -->
            <v-col cols="2" class="pa-2">
                <!-- STATS -->
                <v-card class="mb-2">
                    <v-toolbar flat dense dark color="orange darken-2">
                        <v-toolbar-title><i class="fas fa-info-circle pr-1"></i>Estatísticas</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text class="pt-1 pb-1">
                        <v-row>
                            <v-col cols="12" class="mb-2">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th class="text-center pl-1 pr-1">
                                                Envios
                                                <!--                                                <i class="fas fa-truck pr-1" style="color: forestgreen"></i>-->
                                            </th>
                                            <th class="text-center pl-1 pr-1">
                                                Vol.
                                                <!--                                                <i class="fas fa-box pr-1" style="color: #c3aa83"></i>-->
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td style="font-size: 12px">
                                                <v-img
                                                        src="@/assets/img/logo_ctt.png"
                                                        contain
                                                        style="max-width: 50px"
                                                        class="ma-1"
                                                ></v-img>
                                                <!--                                                CTT-->
                                            </td>
                                            <td class="text-center">{{ totalCTT }}</td>
                                            <td class="text-center">{{ totalVolCTT }}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 12px">
                                                <v-img
                                                        src="@/assets/img/logo_dpd.png"
                                                        contain
                                                        style="max-width: 45px"
                                                        class="ma-1"
                                                ></v-img>
                                                <!--                                                DPD-->
                                            </td>
                                            <td class="text-center">{{ totalDPD }}</td>
                                            <td class="text-center">{{ totalVolDPD }}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 12px">
                                                <v-img
                                                        src="@/assets/img/logo_redur.png"
                                                        contain
                                                        style="max-width: 50px"
                                                        class="ma-1"
                                                ></v-img>
                                                <!--                                                REDUR-->
                                            </td>
                                            <td class="text-center">{{ totalRedur }}</td>
                                            <td class="text-center">{{ totalVolRedur }}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 12px">
                                                <v-img
                                                        src="@/assets/img/logo_nacex.png"
                                                        contain
                                                        style="max-width: 50px"
                                                        class="ma-1"
                                                ></v-img>
                                                <!--                                                NACEX-->
                                            </td>
                                            <td class="text-center">{{ totalNacex }}</td>
                                            <td class="text-center">{{ totalVolNacex }}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 12px">
                                                <v-img
                                                        src="@/assets/img/transnautica_2.png"
                                                        contain
                                                        style="max-width: 50px"
                                                        class="ma-1"
                                                ></v-img>
                                            </td>
                                            <td class="text-center">{{ totalTransnautica }}</td>
                                            <td class="text-center">{{ totalVolTransnautica }}</td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>


                                <!-- TOTAIS -->
                                <v-row class="mt-1"
                                       style="background-color: #f5f5f5;margin: -12px -16px;border-radius: 0 0 4px 4px">
                                    <v-col class="ml-2">
                                        <div style="font-size: 22px; font-weight: bold; ">
                                            {{ ordersSentToday.length }} <i class="fas fa-shipping-fast"
                                                                            style="color: forestgreen; font-size: 18px"></i>
                                        </div>
                                        <h5 style="color: black;">Envios Prontos</h5>
                                    </v-col>
                                    <v-col>
                                        <div style="font-size: 22px; font-weight: bold; ">
                                            {{ totalVolumes }}<i class="fas fa-box pl-1"
                                                                 style="color: #c3aa83; font-size: 18px"></i>
                                        </div>
                                        <h5 style="color: black;">Total Volumes</h5>
                                    </v-col>
                                </v-row>


                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <!-- PRINT DEPOR + EAN AND REF -->
                <v-card>
                    <v-toolbar flat dense dark color="green darken-2">
                        <!--                        <v-toolbar-title class="text-uppercase">Referência Deporvillage</v-toolbar-title>-->
                        <v-toolbar-title><i class="fas fa-tags pr-1"></i>Imprimir Etiquetas</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        hide-details
                                        dense
                                        outlined
                                        clearable
                                        v-model="refDeporvillage"
                                        label="Ref. Deporvillage"
                                ></v-text-field>
                                <v-btn class="mt-1" small color="success" :loading="loadingRefDeporvillage" block
                                       @click="printLabelDeporvillage()">
                                    Imprimir
                                </v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        hide-details
                                        dense
                                        outlined
                                        clearable
                                        v-model="ean"
                                        label="EAN"
                                ></v-text-field>
                                <v-btn class="mt-1" color="warning" small width="49%" :loading="loadingEan"
                                       @click="printLabelEAN()">
                                    Imprimir
                                </v-btn>
                                <v-btn class="mt-1 ml-1" small width="49%" :loading="loadingEan"
                                       @click="downloadLabelRef()">
                                    Imp. Ref.
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- SHIPPINGS READY TO SEND -->
        <v-row class="mt-6">
            <v-col class="pa-2">
                <v-card>
                    <v-toolbar class="mb-3" flat dense dark color="#000000cc">
                        <v-toolbar-title>
                            <i class="fas fa-list"></i>
                            Lista de Envios | <span style="font-size: 15px;color: #00C853"><i
                                class="fas fa-calendar-day pr-1"></i>{{ (new Date().getDate() > 9 ? '' : '0') + new Date().getDate() + '-' + ((new Date().getMonth() + 1) > 9 ? '' : '0') + (new Date().getMonth() + 1) + '-' + new Date().getFullYear() }}</span>
                            <!--                                {{ new Date().toJSON().slice(0, 10) }}-->
                            <!--                            |-->
                            <!--                            <span style="font-size: 16px"><i class="fas fa-truck pr-1" style="color: forestgreen"></i>Envios prontos: <strong>{{ ordersSentToday.length }}</strong></span>-->
                            <!--                            |-->
                            <!--                            <span style="font-size: 16px"><i class="fas fa-box pr-1" style="color: #c3aa83"></i>Total Volumes: <strong>{{ totalVolumes }}</strong></span>-->
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn id="print_manifest_nacex" class="mr-1" small outlined @click="dialogManifestNACEX=true">
                            <i class="fas fa-print mr-1" style="font-size: 16px;"></i> Manifesto <span class="transporter">NACEX</span>
                        </v-btn>
                        <v-btn id="print_manifest_ctt" class="mr-1" small outlined @click="dialogManifestCTT=true">
                            <i class="fas fa-print mr-1" style="font-size: 16px;"></i>  Manifesto <span class="transporter">CTT</span>
                        </v-btn>
                        <v-btn id="print_manifest_redur" class="mr-1" small outlined @click="dialogManifestREDUR=true">
                            <i class="fas fa-print mr-1" style="font-size: 16px;"></i> Manifesto <span class="transporter">REDUR</span>
                        </v-btn>
                        <v-btn id="print_manifest_transnautica" small outlined @click="dialogManifestTRANSNAUTICA=true">
                            <i class="fas fa-print mr-1" style="font-size: 16px;"></i> Manifesto <span class="transporter">TRANSNAUTICA</span>
                        </v-btn>
                        <!--  <v-btn small outlined @click="printManifesto('SEUR')">Imprimir Manifesto SEUR</v-btn>-->
                        <!--  <v-btn small outlined @click="dialogManifestSEUR=true">Imprimir Manifesto SEUR</v-btn>-->
                    </v-toolbar>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr>
                                <th width="5%">Transp.</th>
                                <th width="22%">Tracking Number</th>
                                <th width="5%">Num.Volumes</th>
                                <th width="8%">Conta</th>
                                <th>Referência</th>
                                <th width="10%">Cod. Cliente</th>
                                <th>Nome do Cliente</th>
                                <th>Faturas</th>
                                <th class="text-center">Email</th>
                                <th class="text-center">Cancelar Envio</th>
                            </tr>
                            <tr class="filters_row">
                                <td>
                                    <v-text-field
                                            v-model="filtersToday.courier"
                                            outlined
                                            hide-details
                                            single-line
                                            @change="updateOrdersSent()"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersToday.trackingNumber"
                                            outlined
                                            hide-details
                                            single-line
                                            @change="updateOrdersSent()"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersToday.quantity"
                                            outlined
                                            hide-details
                                            single-line
                                            @change="updateOrdersSent()"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-select
                                            v-model="filtersToday.account"
                                            outlined
                                            hide-details
                                            single-line
                                            @change="updateOrdersSent()"
                                            :items="filterAccount"
                                            item-text="TEXT"
                                            item-value="VALUE"
                                    ></v-select>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersToday.reference"
                                            outlined
                                            hide-details
                                            single-line
                                            @change="updateOrdersSent()"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersToday.entity"
                                            outlined
                                            hide-details
                                            single-line
                                            @change="updateOrdersSent()"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersToday.name"
                                            outlined
                                            hide-details
                                            single-line
                                            @change="updateOrdersSent()"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersToday.docs"
                                            outlined
                                            hide-details
                                            single-line
                                            @change="updateOrdersSent()"
                                    ></v-text-field>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="loadingShippedToday">
                                <td colspan="10">
                                    <v-progress-linear indeterminate></v-progress-linear>
                                </td>
                            </tr>
                            <tr v-for="shipped in ordersSentToday">
                                <td class="text-center">{{ shipped.courier }}</td>
                                <td style="line-break: anywhere">
                                    <template v-for="(track, index) in shipped.trackingNumber.split(';')">
                                        {{ track }}
                                        <span v-if="index !== shipped.trackingNumber.split(';').length - 1">, </span>
                                        <br v-if="(index + 1) % 3 === 0">
                                    </template>
                                </td>
                                <td class="text-center">{{ shipped.quantity }}</td>
                                <td class="text-center">{{
                                        shipped.account === seurCodesAccounts.auto.pt || shipped.account ===
                                        seurCodesAccounts.auto.es || shipped.account === null ?
                                                'Normal' :
                                                (shipped.account === seurCodesAccounts.auto_sea.pt || shipped.account ===
                                                seurCodesAccounts.auto_sea.es ? 'Marítimo' : 'Bicicletas')
                                    }}
                                </td>
                                <td class="text-center">
                                    {{ shipped.reference }}
                                    <v-btn icon small
                                           @click="printLabel(shipped)">
                                        <v-icon small>print</v-icon>
                                    </v-btn>
                                </td>
                                <td>{{ shipped.clientCode }}</td>
                                <td>{{ shipped.clientName }}</td>
                                <td>{{ shipped.docs }}</td>
                                <td class="text-center">
                                    <template v-if="shipped.courier == 'SEUR2'">
                                        <v-btn v-if="shipped.emailToDispatch == false" tile small color="success"
                                               @click="sendSeurEmail(shipped)">Enviar
                                        </v-btn>
                                        <span v-else>Enviado</span>
                                    </template>
                                </td>
                                <td class="text-center">
                                    <v-btn small v-if="writePermission('orders/shipped')" color="error"
                                           @click="unmarkOrderAsShipped(shipped)">
                                        <i class="fas fa-times"></i>
                                    </v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- DIALOGS -->
        <v-row justify="center">
            <!-- DOCS -->
            <v-dialog v-model="dialogHideDoc" persistent max-width="290">
                <v-card>
                    <v-card-title>
                        Ocultar o documento
                    </v-card-title>
                    <v-card-text>
                        Tem a certeza que pretende ocultar o documento?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogHideDoc()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="confirmHideDoc()" color="error">
                            Confirmo
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogRecoverDoc" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Recuperar documento
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="docRecover.type"
                                        outlined
                                        label="Tipo"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                        v-model="docRecover.serie"
                                        outlined
                                        label="Série"
                                        class="ml-2"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="docRecover.numDoc"
                                        outlined
                                        label="Número"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                Tem a certeza que pretende recuperar o documento?
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogRecoverDoc()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="confirmRecoverDoc()" color="success">
                            Confirmo
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- CRIAR ETIQUETAS -->
            <!-- NEW DINAMIC DIALOG - Criar etiquetas -->
            <v-dialog v-model="dialogConfigShip" persistent :retain-focus="false" max-width="720">
                <ConfigShipping :courier="courier" :key="courier+'_'+clientSelected.entity" :client="clientSelected"
                                :addresses="addresses" :emptyInputConfigShip="inputConfigShip"
                                :selectedOrders="selectedOrders" @close-modal="dialogConfigShip = false"
                                @get-orders="getOrdersWaitingShipping()"
                                @clear-selected-orders="clearOrders"></ConfigShipping>
            </v-dialog>

            <!-- MANIFESTS -->
            <v-dialog v-model="dialogManifestNACEX" persistent max-width="550">
                <v-card>
                    <v-card-title
                            style="background-color: rgba(0, 0, 0, 0.5);color: white;margin-bottom: 8px;justify-content: space-between;">
                        Confirmar impressão do manifesto NACEX?
                    </v-card-title>
                    <v-card-text style="display:flex;justify-content:center;" class="pt-2 pb-2">
                        <v-img
                                src="@/assets/img/logo_nacex.png"
                                style=" max-width: 150px;">
                        </v-img>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogManifestNACEX=false" style="min-width:180px" small color="error">Cancelar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="printManifesto('NACEX'); dialogManifestNACEX=false" style="min-width:180px" small
                               color="success">
                            Imprimir Manifesto
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogManifestCTT" persistent max-width="550">
                <v-card>
                    <v-card-title
                            style="background-color: rgba(0, 0, 0, 0.5);color: white;margin-bottom: 8px;justify-content: space-between;">
                        Confirmar impressão do manifesto CTT?
                    </v-card-title>
                    <v-card-text style="display:flex;justify-content:center;" class="pt-2 pb-2">
                        <v-img
                                src="@/assets/img/logo_ctt.png"
                                style=" max-width: 150px;">
                        </v-img>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogManifestCTT=false" small color="error" style="min-width:180px">Cancelar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="printManifesto('CTT'); dialogManifestCTT=false" style="min-width:180px" small
                               color="success">
                            Imprimir Manifesto
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogManifestSEUR" persistent max-width="550">
                <v-card>
                    <v-card-title>
                        Confirmar impressão do manifesto SEUR?
                    </v-card-title>
                    <v-card-actions>
                        <v-btn @click="dialogManifestSEUR=false" color="error">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="printManifesto('SEUR'); dialogManifestSEUR=false" color="success">
                            Imprimir Manifesto
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogManifestREDUR" persistent max-width="550">
                <v-card>
                    <v-card-title
                            style="background-color: rgba(0, 0, 0, 0.5);color: white;margin-bottom: 8px;justify-content: space-between;">
                        Confirmar impressão do manifesto REDUR?
                    </v-card-title>
                    <v-card-text style="display:flex;justify-content:center;" class="pt-2 pb-2">
                        <v-img
                                src="@/assets/img/logo_redur.png"
                                style=" max-width: 150px;">
                        </v-img>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogManifestREDUR=false" style="min-width:180px" small color="error">Cancelar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="printManifesto('REDUR'); dialogManifestREDUR=false" style="min-width:180px" small
                               color="success">
                            Imprimir Manifesto
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogManifestTRANSNAUTICA" persistent max-width="550">
                <v-card>
                    <v-card-title
                            style="background-color: rgba(0, 0, 0, 0.5);color: white;margin-bottom: 8px;justify-content: space-between;">
                        Confirmar impressão do manifesto TRANSNAUTICA?
                    </v-card-title>
                    <v-card-text style="display:flex;justify-content:center;" class="pt-2 pb-2">
                        <v-img
                                src="@/assets/img/transnautica_2.png"
                                style="max-width: 270px; max-height: 35px;">
                        </v-img>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogManifestTRANSNAUTICA=false" style="min-width:180px" small color="error">Cancelar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="printManifesto('TRANSNAUTICA'); dialogManifestTRANSNAUTICA=false" style="min-width:180px" small
                               color="success">
                            Imprimir Manifesto
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import i18n from '../../plugins/i18n'
import ConfigShipping from "@/components/Orders/ConfigShipping.vue";

export default {
    components: {ConfigShipping},
    data: () => ({
        loading: false,
        loadingShippedToday: false,
        ordersToSend: [],
        totalOrdersToSend: 0,
        ordersSentToday: [],
        originOrdersSentToday: [],
        totalVolumes: 0,
        originalTotalVolumes: 0,
        filters: {
            entity: '',
            name: '',
            typeDoc: '',
            numDoc: '',
            week: 1
        },
        emptyFilters: {
            entity: '',
            name: '',
            typeDoc: '',
            numDoc: '',
            week: 1
        },
        selectedOrders: [],
        selectedOrdersIds: [],
        clientSelected: {
            entity: '',
            name: '',
            tel: '',
            email: ''
        },
        dialogHideDoc: false,
        dialogRecoverDoc: false,
        dialogManifestCTT: false,
        dialogManifestSEUR: false,
        dialogManifestNACEX: false,
        dialogManifestREDUR: false,
        dialogManifestTRANSNAUTICA: false,
        selectedDoc: '',
        filterWeek: [
            {text: '1 Semana', value: 1},
            {text: '2 Semanas', value: 2},
            {text: '1 Mês', value: 4},
            {text: '2 Meses', value: 8},
            {text: '3 Meses', value: 12},
            {text: '4 Meses', value: 16}
        ],
        itemPerPage: [
            {text: '15', value: 15},
            {text: '25', value: 25},
            {text: '50', value: 50},
            {text: '100', value: 100}
        ],
        docRecover: {
            type: '',
            serie: '',
            numDoc: ''
        },
        courier: '',
        ref: {},
        addresses: [],
        clientDetail: [],
        inputConfigShip: {
            price: '',
            address: {
                Address: '',
                Address2: '',
                Locality: '',
                Cp: '',
                CpLocality: '',
                Country: ''
            },
            payment: '',
            quantity: 0,
            weight: '',
            observation: '',
            type: '1',
            portesDebidos: 0,
            account: 'auto'
        },
        emptyInputConfigShip: {
            price: '',
            address: {
                Address: '',
                Address2: '',
                Locality: '',
                Cp: '',
                CpLocality: '',
                Country: ''
            },
            payment: '',
            quantity: 0,
            weight: '',
            observation: '',
            type: '1',
            portesDebidos: 0,
            account: 'auto'
        },
        changeAddress: false,
        loadingRefDeporvillage: false,
        refDeporvillage: '',
        loadingEan: false,
        ean: '',
        filtersToday: {
            courier: '',
            account: '',
            reference: '',
            entity: '',
            name: '',
            docs: '',
            trackingNumber: '',
            quantity: ''
        },
        pagination: {
            per_page: 15,
            page: 1
        },
        seurCodesAccounts: {
            'auto': {
                'pt': '07287606',
                'es': '07287604'
            },
            'auto_sea': {
                'pt': '07287607',
                'es': '07287605'
            },
            'bikes': '07287612'
        },
        seurCodes: {
            'normal': 'auto',
            'bikes': '07287612',
            'sea': 'auto_sea',
        },
        filterAccount: [
            {'TEXT': 'Todos', 'VALUE': ''},
            {'TEXT': 'Normal', 'VALUE': 'auto'},
            {'TEXT': 'Bicicletas', 'VALUE': 'EMSF010.01'},
            {'TEXT': 'Marítimo', 'VALUE': 'auto_sea'}
        ],
        dialogConfigShip: false,
        totalDPD: 0,
        totalCTT: 0,
        totalRedur: 0,
        totalNacex: 0,
        totalTransnautica: 0,
        totalVolDPD: 0,
        totalVolCTT: 0,
        totalVolRedur: 0,
        totalVolNacex: 0,
        totalVolTransnautica:0
    }),
    created() {
        document.title = 'Configurar Envios'
        this.initialize()
        // this.ordersToSend.push({
        //     "id": "9E0B1A6E-8ADF-11EF-AFA4-005056A55C61",
        //     "client_code": "ES001423",
        //     "client_name": "Deporvillage S.L.",
        //     "Fac_Tel": "0034931591900",
        //     "CDU_Login": "eric.manteca@deporvillage.com",
        //     "vendedor": "I01",
        //     "referencia": null,
        //     "requisicao": null,
        //     "doc_type": "FA",
        //     "doc_number": 22410556,
        //     "doc_serie": "2024",
        //     "order_total": "743.47",
        //     "order_date": "2024-10-15 11:24:15"
        // },{
        //     "id": "3B9773D8-9A97-11EF-AFA4-005056A55C61",
        //     "client_code": "1123",
        //     "client_name": "W Bikes",
        //     "Fac_Tel": null,
        //     "CDU_Login": "wilsonmg9@gmail.com",
        //     "vendedor": "13",
        //     "referencia": null,
        //     "requisicao": null,
        //     "doc_type": "FAP",
        //     "doc_number": 22404275,
        //     "doc_serie": "2024",
        //     "order_total": "103.89",
        //     "order_date": "2024-11-04 10:26:23"
        // })
    },
    methods: {
        initialize() {
            this.getOrdersWaitingShipping()
        },
        getOrdersWaitingShipping() {
            this.loading = true
            this.ordersToSend = []
            this.selectedOrdersIds = []
            this.selectedOrders = []
            this.originOrdersSentToday = [];
            this.ordersSentToday = [];
            //clear totals
            this.totalDPD = 0;
            this.totalCTT = 0;
            this.totalRedur = 0;
            this.totalNacex = 0;
            this.totalTransnautica = 0;
            this.totalVolDPD = 0;
            this.totalVolCTT = 0;
            this.totalVolRedur = 0;
            this.totalVolNacex = 0;
            this.totalVolTransnautica = 0;

            let filters = {
                filters: this.filters,
                pagination: this.pagination
            }

            Order.dispatch('getOrdersWaitingShipping', filters)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.ordersToSend = responseData.waitingShipping
                            this.totalOrdersToSend = responseData.totalWaitingShipping
                        }
                        this.loading = false
                    })
                    .catch(response => {
                        this.loading = false
                    })

            this.loadingShippedToday = true
            Order.dispatch('getOrdersShipped', 'flag_todayonly')
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.ordersSentToday = responseData.shipped
                            this.originOrdersSentToday = this.ordersSentToday
                            this.updateOrdersSent()

                            this.calcTotalsCourier(this.originOrdersSentToday);

                            this.totalVolumes = 0
                            this.ordersSentToday.forEach(item => {
                                this.totalVolumes += parseInt(item.quantity)
                            })

                            if (isNaN(this.totalVolumes))
                                this.totalVolumes = 0
                            this.originalTotalVolumes = this.totalVolumes
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        }
                    })
            this.loadingShippedToday = false
            /*.catch(response => {
                 Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro de servidor!',
                    type: 'error'
                })
                this.loadingShippedToday = false
            })*/
        },
        calcTotalsCourier(ordersSent) {
            for (const order of ordersSent) {
                switch (order.courier) {
                    case "CTT":
                        this.totalCTT += 1;
                        this.totalVolCTT += parseInt(order.quantity);
                        break;
                    case "SEUR2":
                        this.totalDPD += 1;
                        this.totalVolDPD += parseInt(order.quantity);
                        break;
                    case "REDUR":
                        this.totalRedur += 1;
                        this.totalVolRedur += parseInt(order.quantity);
                        break;
                    case "NACEX":
                        this.totalNacex += 1;
                        this.totalVolNacex += parseInt(order.quantity);
                        break;
                    case "TRANSNAUTICA":
                        this.totalTransnautica += 1;
                        this.totalVolTransnautica += parseInt(order.quantity);
                }
            }
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        selectOrder(order) {
            var index = this.selectedOrdersIds.indexOf(order.id)
            if (index >= 0) {
                this.selectedOrdersIds.splice(index, 1)
                this.selectedOrders.splice(index, 1)
            } else {
                this.selectedOrders.push({
                    id: order.id,
                    type: order.doc_type,
                    number: order.doc_number,
                    serie: order.doc_serie,
                    total_doc: order.order_total,
                    vendedor: order.vendedor,
                    reference: order.referencia,
                    requisicao: order.requisicao,
                    shippingAddress: order.shipping_address
                })
                this.selectedOrdersIds.push(order.id)

                this.clientSelected = {
                    entity: order.client_code,
                    name: order.client_name,
                    tel: order.Fac_Tel,
                    email: order.CDU_Login,
                }
            }
        },
        clearOrders() {
            this.selectedOrders = [];
            this.selectedOrdersIds = [];
            this.filters = {
                entity: '',
                name: '',
                typeDoc: '',
                numDoc: '',
                week: 1
            };
            this.clientSelected = {
                entity: '',
                name: '',
                tel: '',
                email: ''
            };
            this.getOrdersWaitingShipping();
        },

        toggleDialogHideDoc(id) {
            this.dialogHideDoc = !this.dialogHideDoc
            if (this.dialogHideDoc) {
                this.selectedDoc = id
            }
        },
        confirmHideDoc() {
            Order.dispatch('hideDoc', this.selectedDoc)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dialogHideDoc = false
                            this.selectedDoc = ''
                            this.getOrdersWaitingShipping()
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro ao ocultar documento!',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        let alert = {
                            snackbar: true,
                            text: 'Erro ao ocultar documento!',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                    })
        },
        toggleDialogRecoverDoc() {
            this.dialogRecoverDoc = !this.dialogRecoverDoc
        },
        confirmRecoverDoc() {
            Order.dispatch('recoverDoc', this.docRecover)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dialogRecoverDoc = false
                            this.docRecover = {
                                type: '',
                                serie: '',
                                numDoc: ''
                            }

                            this.selectedOrders = []
                            this.selectedOrdersIds = []
                            this.getOrdersWaitingShipping()

                            let alert = {
                                snackbar: true,
                                text: 'O documento foi recuperado!',
                                type: 'success'
                            }

                            Alert.dispatch('show', alert)
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro ao recuperar documento!',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        let alert = {
                            snackbar: true,
                            text: 'Erro ao recuperar documento!',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                    })
        },

        //NEW Function to config geral and open component modal
        configShipping(courier) {
            this.courier = courier;
            this.dialogConfigShip = true;
        },

        copyRefToClipboard() {
            let refCodeToCopy = document.querySelector('#ref-code')
            refCodeToCopy.setAttribute('type', 'text')
            refCodeToCopy.select()

            document.execCommand('copy')

            refCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
        printManifesto(courier) {

            let request = {
                url: '/api/staff/orders/manifest',
                data: {
                    courier: courier
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {

                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let alert = {
                                snackbar: true,
                                text: 'A imprimir manifesto ' + courier,
                                type: 'success'
                            }

                            Alert.dispatch('show', alert)
                        } else {
                            let alert = {
                                snackbar: true,
                                text: responseData.data.data,
                                type: 'error',
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        this.loading = false
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao imprimir manifesto!',
                            type: 'error'
                        })
                    })
        },
        printLabelDeporvillage() {
            this.loadingRefDeporvillage = true;
            let request = {
                url: api.orderPrintLabelDeporvillage(),
                data: {
                    barcode: this.refDeporvillage
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'A imprimir etiqueta Deporvillage!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro a imprimir etiqueta!',
                                type: 'error'
                            })
                        }
                        this.loadingRefDeporvillage = false;
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loadingRefDeporvillage = false;
                    })
        },
        downloadLabelRef() {
            this.loadingEan = true;
            let request = {
                url: '/api/staff/order/printLabelRef',
                data: {
                    ref: this.ean
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'A imprimir etiqueta do Artigo!',
                                type: 'success'
                            })
                        } else if (responseData.status === 'WAR') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Não foi encontrado nenhum artigo com este código EAN!',
                                type: 'warning'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro a imprimir etiqueta!',
                                type: 'error'
                            })
                        }
                        this.loadingEan = false;
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loadingEan = false;
                    })
        },
        printLabelEAN() {
            this.loadingEan = true;
            let request = {
                url: api.orderPrintLabelEAN(),
                data: {
                    ean: this.ean
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'A imprimir etiqueta do Artigo!',
                                type: 'success'
                            })
                        } else if (responseData.status === 'WAR') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Não foi encontrado nenhum artigo com este código EAN!',
                                type: 'warning'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro a imprimir etiqueta!',
                                type: 'error'
                            })
                        }
                        this.loadingEan = false;
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loadingEan = false;
                    })
            // this.ean = ''
        },


        unmarkOrderAsShipped(order) {
            this.loadingShippedToday = true
            let request = {
                url: api.orderUnmarkAsShipped(),
                data: {
                    orderShipped: order
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.filtersToday = {
                                courier: '',
                                account: '',
                                reference: '',
                                entity: '',
                                name: '',
                                docs: ''
                            }

                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Envio eliminado com sucesso!',
                                type: 'success'
                            })
                            this.getOrdersWaitingShipping();
                        } else if (responseData.status === 'WARN') {
                            this.loadingShippedToday = false
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: responseData.data.response,
                                type: 'error'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao eliminar envio!',
                                type: 'error'
                            })
                            this.loadingShippedToday = false
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loadingShippedToday = false
                    })
            this.loadingShippedToday = false
        },
        writePermission(permission) {
            return User.getters.writePermission(permission)
        },
        updateOrdersSent() {
            this.ordersSentToday = this.originOrdersSentToday

            if (this.filtersToday.courier !== '') {
                this.ordersSentToday = this.ordersSentToday.filter(order => order.courier.toLowerCase().includes(this.filtersToday.courier.toLowerCase().trim()))
            }
            if (this.filtersToday.account !== '') {
                if (this.filtersToday.account === 'auto')
                    this.ordersSentToday = this.ordersSentToday.filter(order =>
                            order.account === null ||
                            order.account.includes(this.seurCodesAccounts.auto.pt) ||
                            order.account.includes(this.seurCodesAccounts.auto.es)
                    )
                else if (this.filtersToday.account === 'auto_sea')
                    this.ordersSentToday = this.ordersSentToday.filter(order =>
                            order.account !== null && (
                                    order.account.includes(this.seurCodesAccounts.auto_sea.pt) ||
                                    order.account.includes(this.seurCodesAccounts.auto_sea.es))
                    );
                else
                    this.ordersSentToday = this.ordersSentToday.filter(order => order.account !== null &&
                            order.account.toString().toLowerCase().includes(this.filtersToday.account.toLowerCase()))
            }
            if (this.filtersToday.reference !== '') {
                this.ordersSentToday = this.ordersSentToday.filter(order => order.reference.toUpperCase().includes(this.filtersToday.reference.toUpperCase().trim()))
            }
            if (this.filtersToday.entity !== '') {
                this.ordersSentToday = this.ordersSentToday.filter(order => order.clientCode.toUpperCase().includes(this.filtersToday.entity.toUpperCase()))
            }
            if (this.filtersToday.name !== '') {
                this.ordersSentToday = this.ordersSentToday.filter(order => order.clientName.toUpperCase().includes(this.filtersToday.name.toUpperCase()))
            }
            if (this.filtersToday.docs !== '') {
                this.ordersSentToday = this.ordersSentToday.filter(order => order.docs !== '' && order.docs.toUpperCase().includes(this.filtersToday.docs.toUpperCase()))
            }
            if (this.filtersToday.trackingNumber !== '') {
                this.ordersSentToday = this.ordersSentToday.filter(order => order.trackingNumber !== '' && order.trackingNumber.toUpperCase().includes(this.filtersToday.trackingNumber.toUpperCase()))
            }
            if (this.filtersToday.quantity !== '') {
                this.ordersSentToday = this.ordersSentToday.filter(order => order.quantity !== '' && order.quantity.toUpperCase().includes(this.filtersToday.quantity.toUpperCase()))
            }

            this.totalVolumes = 0
            this.ordersSentToday.forEach(item => {
                this.totalVolumes += parseInt(item.quantity)
            })

            if (isNaN(this.totalVolumes))
                this.totalVolumes = 0

        },
        downloadLabelEan() {
            this.loadingEan = true;
            let request = {
                url: '/api/staff/order/imageLabelEan/' + this.ean
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            var a = document.createElement("a");
                            a.href = "data:image/svg+xml;charset=utf-8," + responseData.img;
                            a.download = this.ean + "_barcode.svg";
                            a.click();
                        }

                        // var file = new Blob([response.data], {
                        //     type: 'application/pdf'
                        // });
                        // var fileURL = URL.createObjectURL(file);
                        // var a = document.createElement('a');
                        // a.href = fileURL;
                        // a.target = '_blank';
                        // a.download = this.ean + '.pdf';
                        // document.body.appendChild(a);
                        // a.click();
                        // document.body.removeChild(a);

                        // Alert.dispatch('show', {
                        //     snackbar: true,
                        //     text: 'A imprimir etiqueta do Artigo!',
                        //     type: 'success'
                        // })
                        // } else if (responseData.status === 'WAR') {
                        //     Alert.dispatch('show', {
                        //         snackbar: true,
                        //         text: 'Não foi encontrado nenhum artigo com este código EAN!',
                        //         type: 'warning'
                        //     })
                        // } else {
                        //     Alert.dispatch('show', {
                        //         snackbar: true,
                        //         text: 'Erro a download Código Barras!',
                        //         type: 'error'
                        //     })
                        // }
                        this.loadingEan = false;
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loadingEan = false;
                    })
        },
        printLabel(shipped) {
            let request = {
                url: '/api/staff/shipping/printLabel',
                data: {
                    shipped: shipped
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Envio re-impresso com sucesso!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao imprimir etiqueta!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        sendSeurEmail(shipping) {
            let request = {
                url: '/api/staff/shipping/' + shipping.reference + '/email',
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Email de desenfalgamento enviado com sucesso!',
                                type: 'success'
                            });
                            shipping.emailToDispatch = true;
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao enviar email de desenfalgamento!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        }
    },
    computed: {
        urlAPI() {
            return process.env.VUE_APP_API_URL
        },
        getTotalPages() {
            return Math.ceil(this.totalOrdersToSend / this.pagination.per_page)
        }
    }
}
</script>

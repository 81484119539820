<style scoped>
.v-application .primary--text {
    color: #c00d0d !important;
}

.v-list .v-list-item--active {
    color: #c00d0d !important;
}

.v-list-group .v-list-item {
    padding-right: 12px !important;
}

.v-navigation-drawer .v-list .v-list-group .v-list-item {
    background-color: #f0f0f0;
    min-height: 32px;
    padding-left: 48px;
}

.v-navigation-drawer .v-list .v-list-group .v-list-item--active {
    color: #c00d0d !important;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
    margin: 8px 16px 8px 0 !important;
}
</style>

<template>
    <v-app id="staff">

        <v-navigation-drawer app fixed v-model="drawer" clipped>
            <v-list dense class="pa-0">
                <template v-for="item in items">
                    <template v-if="item.permission">
                        <v-list-group v-if="item.children" :key="item.text" v-model="item.model" no-action>
                            <template v-slot:activator>
                                <v-list-item-action>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.text"></v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <v-list-item v-for="(child, i) in item.children" :key="i" v-if="child.permission"
                                         :to="child.link">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <i class="fas fa-fw fa-caret-right"></i> {{ child.text }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <v-list-item v-else :key="item.text" @click="closeSubMenus()" :to="item.link">
                            <v-list-item-action>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </template>
                <v-list-item href="https://meocloud.pt/link/5fd44da7-8b7c-4b72-9438-c8d4094b5ce5/SGQ/" target="_blank">
                    <v-list-item-action>
                        <v-icon>link</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Sistema de Qualidade
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar
                :clipped-left="$vuetify.breakpoint.lgAndUp"
                color="red"
                class="staff_app-bar"
                dense
                dark
                app
                fixed
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <v-img
                    :src="require('../assets/img/logotipo.png')"
                    aspect-ratio="1"
                    max-height="32"
                    max-width="94"
                    @click="goToDashboard()"
                    class="mx-1"
                    style="cursor: pointer"
            ></v-img>

            <v-text-field
                    v-if="false"
                    flat
                    solo-inverted
                    hide-details
                    prepend-inner-icon="search"
                    label="Search"
                    class="hidden-sm-and-down"
            ></v-text-field>

            <v-spacer></v-spacer>

            <!-- Informática -->
            <v-btn outlined small tile class="mr-2" @click="callExternalForm('info')">
                Informática
            </v-btn>


            <!-- Marketing -->
            <v-btn outlined small tile class="mr-2" @click="callExternalForm('mkt')">
                Marketing
            </v-btn>


            <!-- Newsletter -->
            <!--            <v-btn outlined small tile class="mr-2" @click="callExternalForm('news')">-->
            <!--                Newsletter-->
            <!--            </v-btn>-->


            <span class="mr-4">{{ getUserName }}</span>

            <!-- PROFILE -->
            <v-menu offset-y v-if="changeProfile && profileName">
                <template v-slot:activator="{ on }">
                    <v-btn outlined tile v-on="on" class="mr-2">
                        {{ profileName }}
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="profile in profiles" :key="profile.id" @click="saveChangeProfile(profile.id)">
                        <v-list-item-title>
                            {{ profile.profile }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- LANGUAGE~-->
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        {{ language }}
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
                        <v-list-item-title>
                            <flag :iso="entry.flag" v-bind:squared=false></flag>
                            {{ entry.title }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- NOTIFICATIONS -->
            <v-menu offset-y :close-on-content-click="false">

                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-badge :content="countNotifications" :value="countNotifications" color="warning" overlap>
                            <v-icon>notifications</v-icon>
                        </v-badge>
                    </v-btn>
                </template>

                <v-list two-line min-width="400">


                    <!-- WITH NOTIFICATIONS -->
                    <v-list-item style="cursor:pointer" v-for="notification in notifications" :key="notification.id">

                        <v-list-item-avatar>
                            <v-icon :class="notificationColor(notification.type)">{{
                                    notificationIcon(notification.type)
                                }}
                            </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title @click="notificationRoute(notification)">{{ notification.message }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <v-chip v-if="notification.viewed === 0" x-small label color="primary" class="px-1">
                                    NOVO
                                </v-chip>
                                {{ notification.date }}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-btn icon @click="deleteNotification(notification.id)" style="cursor: pointer">
                                <v-icon color="grey lighten-1">delete</v-icon>
                            </v-btn>
                        </v-list-item-action>

                    </v-list-item>

                    <!-- WITHOUT NOTIFICATIONS -->
                    <v-list-item v-if="notifications.length === 0" @click="getNotifications()">
                        <v-list-item-avatar></v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>Não existem notificações!</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action></v-list-item-action>
                    </v-list-item>

                </v-list>

            </v-menu>

            <v-btn v-if="$writePermission('online/bikeplanet/orders')" @click="goToBikeplanetOrders()" icon>
                <v-badge :content="ordersB2C" :value="ordersB2C" color="green" overlap>
                    <v-icon>shopping_cart</v-icon>
                </v-badge>
            </v-btn>

            <!-- JOBS -->
            <v-btn v-if="isSuper" icon @click="getNotifications" class="mr-1">
                <v-badge :content="jobsRun" :value="jobsRun" color="green" overlap>
                    <v-icon>sync</v-icon>
                </v-badge>
            </v-btn>

        </v-app-bar>

        <v-main>

            <router-view/>

        </v-main>

        <Alert/>

    </v-app>
</template>

<style src="../assets/css/custom.css"></style>

<script>
import User from '../models/User'
import i18n from '../plugins/i18n'
import Alert from '../components/Common/Alert'

export default {
    components: {
        Alert
    },
    beforeCreate() {
        i18n.locale = User.getters.getLanguage
    },
    data: function () {
        return {
            countNotifications: 0,
            notifications: [],
            languages: [
                {flag: 'pt', language: 'pt', title: 'Português'},
                {flag: 'es', language: 'es', title: 'Español'}
            ],

            dialog: false,
            drawer: null,
            profiles: [],
            profileSelect: '',
            jobsRun: 0,
            ordersB2C: 0,
            items: [
                {
                    icon: 'home',
                    text: i18n.t("home"),
                    model: false,
                    link: '/dashboard',
                    permission: true
                },
                {
                    icon: 'computer',
                    text: 'B2B',
                    model: window.location.pathname.includes('/b2b'),
                    permission: User.getters.readPermission('b2b'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Logins',
                            link: '/b2b/logins',
                            permission: User.getters.readPermission('b2b')
                        },
                       /* {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Banners',
                            link: '/b2b/banners',
                            permission: User.getters.readPermission('b2b/banners')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Mini Banners',
                            link: '/b2b/miniBanners',
                            permission: User.getters.readPermission('b2b/miniBanners')
                        },*/
                        // {
                        //     icon: 'fas fa-fw fa-angle-right',
                        //     text: 'Campanhas',
                        //     link: '/b2b/campaigns',
                        //     permission: User.getters.readPermission('b2b/campaigns')
                        // },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Campanhas',
                            link: '/b2b/campaigns2',
                            permission: User.getters.readPermission('b2b/campaigns2')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Campanhas Histórico',
                            link: '/b2b/campaigns/historic',
                            permission: User.getters.readPermission('b2b/campaigns/historic')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Preços Service Center',
                            link: '/b2b/priceSC',
                            permission: User.getters.readPermission('b2b/priceSC')
                        },
                        // {
                        //     icon: 'fas fa-fw fa-angle-right',
                        //     text: 'Cupões',
                        //     link: '/b2b/coupons',
                        //     permission: User.getters.readPermission('b2b/coupons')
                        // }
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'BannersB2B',
                            link: '/b2b/BannersB2B',
                            permission: User.getters.readPermission('b2b/BannersB2B')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'BannerTypes',
                            link: '/b2b/BannerTypes',
                            permission: User.getters.readPermission('b2b/BannerTypes')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'BannerLocation',
                            link: '/b2b/BannerLocation',
                            permission: User.getters.readPermission('b2b/BannerLocation')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: "Artigos Recomendados",
                            link: '/b2b/recommended-products',
                            permission: User.getters.readPermission('b2b/recommended-products')
                        }
                    ]
                },
                {
                    icon: 'assessment',
                    text: "Questionarios",
                    model: window.location.pathname.includes('/forms'),
                    permission: User.getters.readPermission('forms'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Lista de questionarios',
                            link: '/forms',
                            permission: User.getters.readPermission('forms')
                        }
                    ]
                },
                {
                    icon: 'language',
                    text: 'BikePlanet',
                    model: window.location.pathname.includes('/bikeplanet'),
                    permission: User.getters.readPermission('bikeplanet'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Fat. Amazon',
                            link: '/bikeplanet/invoice-amazon',
                            permission: User.getters.readPermission('bikeplanet/invoice-amazon')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Amazon Commingling',
                            link: '/bikeplanet/amazon-commingling',
                            permission: User.getters.readPermission('bikeplanet/invoice-amazon')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Hist. Enc. Amazon',
                            link: '/bikeplanet/history-amazon',
                            permission: User.getters.readPermission('bikeplanet/history-amazon')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Custos. Enc. Amazon',
                            link: '/bikeplanet/costs-amazon',
                            permission: User.getters.readPermission('bikeplanet/costs-amazon')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Listagem Amazon',
                            link: '/bikeplanet/amazon-listing',
                            permission: User.getters.readPermission('bikeplanet/history-amazon')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Recibos',
                            link: '/bikeplanet/receipts',
                            permission: User.getters.readPermission('bikeplanet/invoice-amazon')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'PDF Compras',
                            link: '/bikeplanet/pdfs-purchases',
                            permission: User.getters.readPermission('bikeplanet/pdfs-purchases')
                        }
                    ]
                },
                {
                    icon: 'public',
                    text: "Bicimax.com",
                    model: window.location.pathname.includes('/bicimax-com'),
                    permission: User.getters.readPermission('bicimax-com'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Artigos',
                            link: '/bicimax-com/products',
                            permission: User.getters.readPermission('bicimax-com/products')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Encomendas',
                            link: '/bicimax-com/orders',
                            permission: User.getters.readPermission('bicimax-com/orders')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Banners',
                            link: '/bicimax-com/banners',
                            permission: User.getters.readPermission('bicimax-com/banners')
                        },
                    ]
                },
                {
                    icon: 'public',
                    text: i18n.t("onlineStores"),
                    model: window.location.pathname.includes('/online'),
                    permission: User.getters.readPermission('online'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('nozamaOrders'),
                            link: '/online/nozama/orders',
                            permission: User.getters.readPermission('online/nozama/orders')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('nozamaTemplates'),
                            link: '/online/nozama/templates',
                            permission: User.getters.readPermission('online/nozama/templates')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: "Nozama - Estatísticas",
                            link: '/online/nozama/stats',
                            permission: User.getters.readPermission('online/nozama/stats')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Bikeplanet - ' + i18n.t('orders'),
                            link: '/online/bikeplanet/orders',
                            permission: User.getters.readPermission('online/bikeplanet/orders')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('products') + ' Planet',
                            link: '/online/products',
                            permission: User.getters.readPermission('online/products')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Documentos PLANET',
                            link: '/online/docs/planet',
                            permission: User.getters.readPermission('online/docs/planet')
                        }
                    ]
                },
                // {
                //     icon: 'motorcycle',
                //     text: 'BikePlanet',
                //     model: window.location.pathname.includes('/bikeplanet'),
                //     permission: User.getters.readPermission('bikeplanet'),
                //     children: [
                //         {
                //             icon: 'fas fa-fw fa-angle-right',
                //             text: 'DropShipping',
                //             link: '/bikeplanet/dropshipping',
                //             permission: User.getters.readPermission('bikeplanet/dropshipping')
                //         },
                //         // {
                //         //     icon: 'fas fa-fw fa-angle-right',
                //         //     text: 'Artigos B2C - X',
                //         //     link: '/bikeplanet/products',
                //         //     permission: User.getters.readPermission('bikeplanet/products')
                //         // },
                //         // {
                //         //     icon: 'fas fa-fw fa-angle-right',
                //         //     text: 'Reports - X',
                //         //     link: '/bikeplanet/reports',
                //         //     permission: User.getters.readPermission('bikeplanet/reports')
                //         // },
                //     ]
                // },
                // {
                //     icon: 'text_rotation_none',
                //     text: "Nozama",
                //     model: window.location.pathname.includes('/nozama/'),
                //     permission: User.getters.readPermission('nozama'),
                //     children: [
                //         {
                //             icon: 'fas fa-fw fa-angle-right',
                //             text: i18n.t('orders'),
                //             link: '/nozama/orders',
                //             permission: User.getters.readPermission('nozama/orders')
                //         },
                //         {
                //             icon: 'fas fa-fw fa-angle-right',
                //             text: "Export Templates",
                //             link: '/nozama/exportTemplates',
                //             permission: User.getters.readPermission('nozama/exportTemplates')
                //         },
                //         {
                //             icon: 'fas fa-fw fa-angle-right',
                //             text: "Estatísticas",
                //             link: '/nozama/stats',
                //             permission: User.getters.readPermission('nozama/stats')
                //         },
                //     ]
                // },
                {
                    icon: 'font_download',
                    text: i18n.t("products"),
                    model: window.location.pathname.startsWith('/products'),
                    permission: User.getters.readPermission('products'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t("products"),
                            link: '/products/products',
                            permission: User.getters.readPermission('products/products')
                        },
                        // {
                        //     text: 'Bicicletas',
                        //     link: '/products/bikes',
                        //     permission: User.getters.readPermission('products/bikes')
                        // },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Famílias',
                            link: '/products/families',
                            permission: User.getters.readPermission('products/families')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t("warehouse"),
                            link: '/products/warehouse',
                            permission: User.getters.readPermission('products/warehouse')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t("discount") + 's Marcas',
                            link: '/products/brandsDiscount',
                            permission: User.getters.readPermission('products/brandsDiscount')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Ecovalor',
                            link: '/products/ecovalor',
                            permission: User.getters.readPermission('products/ecovalor')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t("images"),
                            link: '/products/images',
                            permission: User.getters.readPermission('products/images')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t("monos"),
                            link: '/products/monos',
                            permission: User.getters.readPermission('products/monos')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t("create") + ' ' + i18n.t("products"),
                            link: '/products/create',
                            permission: User.getters.readPermission('products/create')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t("problems"),
                            link: '/products/problems/index',
                            permission: User.getters.readPermission('products/problems')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: "EAN13",
                            link: '/products/ean13',
                            permission: User.getters.readPermission('products/codbarrasax')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: "Lotes A Expirar",
                            link: '/products/expiringLotes',
                            permission: User.getters.readPermission('products/expiringLotes')
                        },
                    ]
                },
                {
                    icon: 'style',
                    text: i18n.t('brandsManagement'),
                    model: window.location.pathname.includes('/brands/'),
                    permission: User.getters.readPermission('brands'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('management') + ' ' + i18n.t('products'),
                            link: '/brands/stock',
                            permission: User.getters.readPermission('brands/stock')
                        },
                        /*{
                              text: i18n.t('management') + ' Bicicletas',
                              link: '/brands/bikes',
                              permission: User.getters.readPermission('brands/bikes')
                          }*/
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('products') + " com problemas",
                            link: '/brands/problems',
                            permission: User.getters.readPermission('brands/problems')
                        }
                    ]
                },
                {
                    icon: 'group',
                    text: 'Clientes',
                    model: window.location.pathname.includes('/clients'),
                    permission: User.getters.readPermission('clients'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Clientes',
                            link: '/clients/clients',
                            permission: User.getters.readPermission('clients/clients')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Déb Directos',
                            link: '/clients/debits',
                            permission: User.getters.readPermission('clients/directDebits')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Clientes Problemas',
                            link: '/clients/clientsProblem',
                            permission: User.getters.readPermission('clients/clientsProblem')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Novos Registos',
                            link: '/clients/clientsRegistration',
                            permission: User.getters.readPermission('clients/clientsRegistration')
                        },
                    ]
                },
                {
                    icon: 'shopping_basket',
                    text: 'Open Orders',
                    model: window.location.pathname.startsWith('/purchases'),
                    permission: User.getters.readPermission('purchases'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Compras',
                            link: '/purchases/purchases',
                            permission: User.getters.readPermission('purchases/purchases')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Atrasos',
                            link: '/purchases/outdated',
                            permission: User.getters.readPermission('purchases/purchases')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Importações',
                            link: '/purchases/openOrders',
                            permission: User.getters.readPermission('purchases/openOrders')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Detalhes Fornecedor',
                            link: '/purchases/details',
                            permission: User.getters.readPermission('purchases/openOrders')
                        },
                    ]
                },
                {
                    icon: 'shopping_basket',
                    text: 'Compras',
                    model: window.location.pathname.startsWith('/purchases'),
                    permission: User.getters.readPermission('purchases'),
                    children: [
                        /*{
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Compras',
                            link: '/purchases/purchases',
                            permission: User.getters.readPermission('purchases/purchases')
                        },*/
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Picking',
                            link: '/purchases/pick',
                            permission: User.getters.readPermission('orders/pick')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'VFAs',
                            link: '/purchases/VFAs',
                            permission: User.getters.readPermission('purchases/VFAs')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'PDF Compras',
                            link: '/purchases/pdfs',
                            permission: User.getters.readPermission('purchases/pdfs')
                        }
                        /*{
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Atrasos',
                            link: '/purchases/outdated',
                            permission: User.getters.readPermission('purchases/purchases')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'OpenOrders',
                            link: '/purchases/openOrders',
                            permission: User.getters.readPermission('purchases/openOrders')
                        },
                         */
                    ]
                },
                {
                    icon: 'shopping_cart',
                    text: i18n.t("orders"),
                    model: window.location.pathname.startsWith('/orders'),
                    permission: User.getters.readPermission('orders'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t("orders"),
                            link: '/orders/orders',
                            permission: User.getters.readPermission('orders/orders')
                        },
                        // {
                        //     icon: 'fas fa-fw fa-angle-right',
                        //     text: i18n.t("orders") + ' Bicicletas',
                        //     link: '/orders/bikes',
                        //     permission: User.getters.readPermission('orders/bikes')
                        // },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t("orders") + ' Disponibilidade',
                            link: '/orders/ordersAvailability',
                            permission: User.getters.readPermission('orders/orders')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Picking',
                            link: '/orders/pick',
                            permission: User.getters.readPermission('orders/pick')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Histórico de pickings',
                            link: '/orders/pick-history',
                            permission: User.getters.readPermission('orders/pick')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Pró-Formas',
                            link: '/orders/proformas',
                            permission: User.getters.readPermission('orders/proformas')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('shipping'),
                            link: '/orders/shippingv2',
                            permission: User.getters.readPermission('orders/shipping')
                        },
                        // {
                        //     icon: 'fas fa-fw fa-angle-right',
                        //     text: i18n.t('shipping') + ' OLD',
                        //     link: '/orders/shipping',
                        //     permission: User.getters.readPermission('orders/shipping')
                        // },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Entregas ' + i18n.t('pending'),
                            link: '/orders/shippedPending',
                            permission: User.getters.readPermission('orders/shipped')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Histórico de envios',
                            link: '/orders/shippedHistory',
                            permission: User.getters.readPermission('orders/shipped')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Histórico ' + i18n.t('orders'),
                            link: '/orders/histOrders',
                            permission: User.getters.readPermission('orders/histOrders')
                        },
                        {
                            text: 'Portes',
                            link: '/orders/shippingCosts',
                            permission: User.getters.readPermission('orders/shippingCosts')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Edifact',
                            link: '/orders/edifact',
                            permission: User.getters.readPermission('orders/edifact')
                        }
                        ,
                        {
                            text: 'Recolhas',
                            link: '/orders/pickups',
                            permission: User.getters.readPermission('orders/pickups')
                        },
                        {
                            text: 'Datas Entrega',
                            link: '/orders/deliveryDate',
                            permission: User.getters.readPermission('orders/deliveryDate')
                        }
                    ]
                },
                {
                    icon: 'local_atm',
                    text: 'Contas Correntes',
                    model: window.location.pathname.includes('/accounts'),
                    permission: User.getters.readPermission('accounts'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Pendentes',
                            link: '/accounts/pending',
                            permission: User.getters.readPermission('accounts/pending')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Notas de Crédito',
                            link: '/accounts/credits',
                            permission: User.getters.readPermission('accounts/credits')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Reembolsos',
                            link: '/accounts/payments',
                            permission: User.getters.readPermission('accounts/payments')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Cosec',
                            link: '/accounts/cosec',
                            permission: User.getters.readPermission('accounts/cosec')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Faturas de Oferta',
                            link: '/accounts/gift-invoices',
                            permission: User.getters.readPermission('accounts/gift-invoices')
                        }
                    ]
                },
                {
                    icon: 'local_atm',
                    text: 'Offmax',
                    model: window.location.pathname.includes('/offmax'),
                    permission: User.getters.readPermission('offmax'),
                    children: [
                        // {
                        //     icon: 'fas fa-fw fa-angle-right',
                        //     text: 'Pendentes',
                        //     link: '/accounts/pending',
                        //     permission: User.getters.readPermission('accounts/pending')
                        // },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Notas de Crédito',
                            link: '/offmax/credits',
                            permission: User.getters.readPermission('offmax/credits')
                        },
                        // {
                        //     icon: 'fas fa-fw fa-angle-right',
                        //     text: 'Reembolsos',
                        //     link: '/accounts/payments',
                        //     permission: User.getters.readPermission('accounts/payments')
                        // },
                    ]
                },
                {
                    icon: 'account_balance',
                    text: 'Débitos Directos',
                    model: window.location.pathname.includes('/debits/'),
                    permission: User.getters.readPermission('debits/debits'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Débitos',
                            link: '/debits/debits',
                            permission: User.getters.readPermission('debits/debits')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Adicionar Linhas',
                            link: '/debits/addLines',
                            permission: User.getters.readPermission('debits/debits')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Histórico de Débitos',
                            link: '/debits/historic',
                            permission: User.getters.readPermission('debits/debits')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Mandatos',
                            link: '/debits/mandates',
                            permission: User.getters.readPermission('debits/debits')
                        }
                    ]
                },
                {
                    icon: 'mail',
                    text: 'Marketing',
                    model: window.location.pathname.includes('/marketing'),
                    permission: User.getters.readPermission('marketing'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Mailchimp',//i18n.t('sales'),
                            link: '/marketing/mailchimp',
                            permission: User.getters.readPermission('marketing/mailchimp')
                        }
                    ]
                },
                {
                    icon: 'supervised_user_circle',
                    text: 'Comercial',
                    model: window.location.pathname.includes('/commercial'),
                    permission: User.getters.readPermission('commercial'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('sales'),
                            link: '/commercial/sales',
                            permission: User.getters.readPermission('commercial/sales')
                        },
                        // {
                        //     icon: 'fas fa-fw fa-angle-right',
                        //     text: i18n.t('commissions') + ' - X',
                        //     link: '/commercial/commissions',
                        //     permission: User.getters.readPermission('commercial/commissions')
                        // },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('budgets'),
                            link: '/commercial/budgets',
                            permission: User.getters.readPermission('commercial/budgets')
                        },
                        // {
                        //     icon: 'fas fa-fw fa-angle-right',
                        //     text: i18n.t('products') + ' FOX - X',
                        //     link: '/commercial/products/fox',
                        //     permission: User.getters.readPermission('commercial/products/fox')
                        // },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('reportsApp'),
                            link: '/commercial/reportsApp',
                            permission: User.getters.readPermission('commercial/reportsApp')
                        },
                        // {
                        //     icon: 'fas fa-fw fa-angle-right',
                        //     text: 'Logins Clientes - X',
                        //     link: '/commercial/loginClients',
                        //     permission: User.getters.readPermission('commercial/loginClients')
                        // }
                    ]
                },
                {
                    icon: 'assignment',
                    text: 'Reports Comercial',
                    model: window.location.pathname.includes('/reportsCommercial'),
                    permission: User.getters.readPermission('reports/commercial'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('sales') + ' por Marca',
                            link: '/reportsCommercial/sales/brand',
                            permission: User.getters.readPermission('reports/commercial')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('sales') + ' por Zona',
                            link: '/reportsCommercial/sales/zone',
                            permission: User.getters.readPermission('reports/commercial')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('sales') + ' Zona/Cliente',
                            link: '/reportsCommercial/sales/zoneClient',
                            permission: User.getters.readPermission('reports/commercial')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('sales') + ' Cliente/Ano',
                            link: '/reportsCommercial/sales/client/year',
                            permission: User.getters.readPermission('reports/commercial')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('sales') + ' Cliente/Marca',
                            link: '/reportsCommercial/sales/client/brand',
                            permission: User.getters.readPermission('reports/commercial')
                        },
                        // {
                        //     icon: 'fas fa-fw fa-angle-right',
                        //     text: i18n.t('sales') + ' por Secção',
                        //     link: '/reports/sales/section',
                        //     permission: User.getters.readPermission('reports/sales/section')
                        // },
                        // {
                        //     icon: 'fas fa-fw fa-angle-right',
                        //     text: i18n.t('commissions'),
                        //     link: '/reports/commissions',
                        //     permission: User.getters.readPermission('reports/commissions')
                        // }
                    ]
                },
                {
                    icon: 'assignment',
                    text: 'Reports Admin',
                    model: window.location.pathname.startsWith('/reportsAdmin'),
                    permission: User.getters.readPermission('reportsAdmin'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t('sales') + ' por Secção',
                            link: '/reportsAdmin/sales/section',
                            permission: User.getters.readPermission('reportsAdmin/sales/section')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'CRM',
                            link: '/reportsAdmin/crm',
                            permission: User.getters.readPermission('reportsAdmin/crm')
                        },
                    ]
                },
                // {
                //     icon: 'settings_phone',
                //     text: 'CRM - X',
                //     model: window.location.pathname.includes('/crm'),
                //     permission: User.getters.readPermission('crm'),
                //     children: [
                //         {
                //             icon: 'fas fa-fw fa-angle-right',
                //             text: 'Entrada - X',
                //             link: '/crm/input',
                //             permission: User.getters.readPermission('crm')
                //         },
                //         {
                //             icon: 'fas fa-fw fa-angle-right',
                //             text: i18n.t('output') + ' - X',
                //             link: '/crm/output',
                //             permission: User.getters.readPermission('crm')
                //         },
                //         {
                //             icon: 'fas fa-fw fa-angle-right',
                //             text: 'Tipos de ' + i18n.t('call') + ' - X',
                //             link: '/crm/typeCall',
                //             permission: User.getters.readPermission('crm/typeCall')
                //         }
                //     ]
                // },
                {
                    icon: 'local_parking',
                    text: 'Primavera',
                    model: window.location.pathname.includes('/primavera'),
                    permission: User.getters.readPermission('primavera'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Perfis',
                            link: '/primavera/profiles',
                            permission: User.getters.readPermission('primavera/profiles')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Intrastat',
                            link: '/primavera/intrastat',
                            permission: User.getters.readPermission('primavera/intrastat')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Intrastat - Compras',
                            link: '/primavera/intrastatCompras',
                            permission: User.getters.readPermission('primavera/intrastat')
                        }
                    ]
                },
                {
                    icon: 'stars',
                    text: 'Formações',
                    model: window.location.pathname.includes('/courses'),
                    permission: User.getters.readPermission('courses'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Formações',
                            link: '/courses/courses',
                            permission: User.getters.readPermission('courses/courses')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Localizações',
                            link: '/courses/places',
                            permission: User.getters.readPermission('courses/courses')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: 'Níveis',
                            link: '/courses/levels',
                            permission: User.getters.readPermission('courses/courses')
                        }
                    ]
                },
                {
                    icon: 'settings_applications',
                    text: i18n.t("administration"),
                    model: window.location.pathname.includes('/administration'),
                    permission: User.getters.readPermission('administration'),
                    children: [
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t("users"),
                            link: '/administration/users',
                            permission: User.getters.readPermission('administration/users')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t("permissions"),
                            link: '/administration/permissions',
                            permission: User.getters.readPermission('administration/permissions')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: i18n.t("profiles"),
                            link: '/administration/profiles',
                            permission: User.getters.readPermission('administration/profiles')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: "Logins",
                            link: '/administration/logins',
                            permission: User.getters.readPermission('administration/logins')
                        },
                        {
                            icon: 'fas fa-fw fa-angle-right',
                            text: "Logs",
                            link: "/administration/logs",
                            permission: User.getters.readPermission('administration/logs')
                        }
                    ],
                },
                {
                    icon: 'power_settings_new',
                    text: 'Logout',
                    link: '/user/logout',
                    permission: true
                }
            ]
        }
    },
    props: {
        source: String
    },
    computed: {
        language: function () {
            return User.getters.getLanguage
        },
        route: function () {
            return this.$route.path
        },
        profileName: function () {
            for (let i = 0; i < this.profiles.length; i++) {
                if (this.profiles[i].id === this.profileSelect) {
                    return this.profiles[i].profile
                }
            }
        },
        changeProfile: function () {
            return User.getters.getChangeProfile
        },
        isSuper: function () {
            return User.getters.getProfileId === 1
        },
        getUserName: function () {
            return User.getters.getUserName
        }
    },
    methods: {
        changeLocale(locale) {
            User.dispatch('changeLanguage', locale)
        },
        closeSubMenus() {
            this.items.forEach(function (value, key) {
                value.model = false;
            });
        },
        getNotifications() {
            User.dispatch('getNotifications')
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.notifications = responseData.notifications;
                            this.countNotifications = responseData.countNotifications;
                            this.jobsRun = responseData.jobsRun;
                            this.ordersB2C = responseData.ordersB2C;
                        }
                    })
                    .catch(() => {
                    });
            setTimeout(() => {
                this.getNotifications()
            }, 1000 * 60)
        },
        notificationRoute(notification) {
            if (notification.viewed === 0) {
                this.countNotifications--;
                notification.viewed = 1;
                User.dispatch('readNotification', notification.id)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                this.notifications = responseData.notifications
                                this.countNotifications = responseData.countNotifications
                            }
                        })
                        .catch(() => {

                        });
            }
            if (notification.link !== '') {
                this.$router.push({path: notification.link})
            }
        },
        notificationColor(type) {
            let color = 'grey';
            if (type === 'success') {
                color = 'green';
            } else if (type === 'error') {
                color = 'red';
            } else if (type === 'info') {
                color = 'cyan';
            } else if (type === 'warning') {
                color = 'orange';
            }
            return color + ' lighten-1 white--text';
        },
        notificationIcon(type) {
            let icon = '';
            if (type === 'success') {
                icon = 'check_circle';
            } else if (type === 'error') {
                icon = 'remove_circle';
            } else if (type === 'info') {
                icon = 'info';
            } else if (type === 'warning') {
                icon = 'warning';
            }
            return icon;
        },
        deleteNotification(id) {
            User.dispatch('deleteNotification', id)
                    .then(response => {
                        this.getNotifications()
                    })
                    .catch(response => {

                    })
        },
        goToDashboard() {
            this.closeSubMenus()
            this.$router.push('/dashboard')
        },
        goToBikeplanetOrders() {
            this.$router.push('/online/bikeplanet/orders')
        },
        getProfiles() {
            let request = {
                url: api.profiles()
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.profiles = responseData.data
                        }
                    })
                    .catch(response => {

                    })
        },
        saveChangeProfile(profileId) {
            let request = {
                url: api.userUpdateProfile(),
                data: {
                    profileId: profileId
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            User.commit('updateCurrentUser', responseData.user)
                            User.commit('updatePermissions', responseData.permissions)
                        }
                    })
                    .catch(response => {

                    })
        },
        callExternalForm(id) {
            switch (id) {
                case 'info':
                    window.open('https://form.asana.com?k=0Zjl2a6hV5K4KX6qjVGExQ&d=1200033071522804', "_blank")
                    break;
                case 'mkt':
                    window.open('https://form.asana.com/?k=vSM3kwCd-5xosVtNRXXxUg&d=1200033071522804', "_blank")
                    break;
                case 'news':
                    window.open('https://form.asana.com?k=YN4O1V5Kv6k3shKh8vNYjw&d=1200033071522804', "_blank")
                    break;
            }

        }
    },
    created() {
        this.getNotifications()
        this.profileSelect = User.getters.getProfileId
        this.getProfiles()
    }
}
</script>
